import React, { useEffect, useState } from 'react';
import {
  Text,
  Button,
  CheckIcon,
  ColorSwatch,
  Group,
  Input,
  Stack,
  TextInput,
  Title,
  useMantineTheme,
  ActionIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useSession } from '../../hooks/useSession';
import { useApi } from '../../hooks/useApi';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { t } from 'ttag';
import { LiitFormContainer } from '../../components/LiitFormContainer/LiitFormContainer';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';
import { Tag } from '../../models/Tag';
import { CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { openConfirmModal } from '@mantine/modals';

interface FormValues {
  id: string;
  name: string;
  color: string;
  isActive: boolean;
}

export const SettingsTagsView: React.FC = () => {
  const api = useApi();
  const [tags, setTags] = useState<Tag[] | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const [formOpened, setFormOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const form = useForm<FormValues>({
    initialValues: { id: '', name: '', color: '', isActive: true },
    transformValues: (values) => ({
      ...values,
    }),
  });

  const theme = useMantineTheme();
  
  const refreshTags = async () => {
    if (!selectedOrganisation) {
      return;
    }

    setIsFetching(true);
    const result = await api.getTags(selectedOrganisation.id);

    setTags(result);
    setIsFetching(false);
  };

  const createTag = async () => {
    form.setFieldValue('id', '');
    form.setFieldValue('name', '');
    form.setFieldValue('color', '');
    form.setFieldValue('isActive', true);
    setFormOpened(true);
  };

  const editTag = async (id: string) => {
    if (!tags) {
      return;
    }

    form.setFieldValue('id', id);

    const tag = tags.find((x) => x.id === id);
    if (tag) {
      form.setFieldValue('name', tag.name);
      form.setFieldValue('color', tag.color);
      form.setFieldValue('isActive', tag.isActive);
    }

    setFormOpened(true);
  };

  const deleteTag = async (id: string) => {
    if (!selectedOrganisation || !tags) {
      return;
    }

    let confirmText = '';
    const tag = tags.find((x) => x.id === id);
    if (tag) {
      if (tag.usageCount > 0) {
        confirmText = t`Vill du ta bort kategorin "${tag.name}"? ${tag.usageCount} st ansökning(ar) är märkta med denna kategorin.`;
      } else {
        confirmText = t`Vill du ta bort kategorin "${tag.name}"?`;
      }
    }

    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{confirmText}</Text>
        </Stack>
      ),
      labels: { confirm: 'OK', cancel: 'Avbryt' },
      onConfirm: async () => {
        const response = await api.deleteTag(selectedOrganisation.id, id);
        if (response.ok) {
          await refreshTags();
        }
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshTags();
    };

    fetchData();
  }, [selectedOrganisation]);

  const save = async (values) => {
    const typeValues = values as Tag;
    if (!selectedOrganisation) {
      return;
    }

    const isNew = !values.id;

    const response = isNew
      ? await api.createTag(selectedOrganisation.id, typeValues)
      : await api.updateTag(selectedOrganisation.id, typeValues);

    if (response.ok) {
      await refreshTags();
      setFormOpened(false);
      const title = isNew ? t`Kategori skapad` : t`Kategori uppdaterad`;
      const message = isNew
        ? t`Kategori ${typeValues.name} har skapats`
        : t`Kategori ${typeValues.name} har uppdaterats`;
      showSuccessNotification(title, message);
      return;
    }

    showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
  };

  const displayColumns: IDisplayColumn[] = [
    {
      displayName: t`Färg`,
      propertyName: 'color',
      propertyType: PropertyType.Text,
      columnStyles: { width: '5%', cursor: 'pointer', paddingLeft: 40 },
      renderCell: (cellInformation: CellRenderInformation) => {
        const stringValue: string = cellInformation.cellValue;
        return (
          <ColorSwatch
            size={16}
            color={`var(--mantine-color-${stringValue.split('.').join('-')}`}
          />
        );
      },
    },
    {
      displayName: t`Namn`,
      propertyName: 'name',
      propertyType: PropertyType.Text,
      columnStyles: { width: '50%', cursor: 'pointer' },
    },
  ];

  const gridButtons: DisplayButtons[] = [
    { name: 'edit', tooltip: t`Redigera`, icon: <IconEdit size={24} />, onClick: (id: string) => editTag(id) },
    { name: 'remove', tooltip: t`Ta bort`, icon: <IconTrash size={24} />, onClick: (id: string) => deleteTag(id) },
  ];

  const getSwatches = (index) => {
    return Object.keys(theme.colors).map((color) => (
      <ColorSwatch
        style={{ color: '#fff', cursor: 'pointer' }}
        size={16}
        onClick={() => form.setFieldValue('color', color + '.' + index)}
        key={color}
        radius={'xs'}
        color={theme.colors[color][index]}>
        {color + '.' + index === form.getInputProps('color').value && <CheckIcon width={10} />}
      </ColorSwatch>
    ));
  };

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Kategorier`}</Title>
          <Button size={'sm'} onClick={() => createTag()} leftSection={<IconPlus />}>
            {t`Ny kategori`}
          </Button>
        </GrantMeTitleContainer>

        <LiitGrid
          data={tags}
          columnInfo={displayColumns}
          displayButtons={gridButtons}
          fetchingData={isFetching}
          onRowClick={(id) => editTag(id)}
        />
      </Stack>
      <LiitDrawer
        titleText={form.values.id ? 'Redigera kategori' : 'Skapa ny kategori'}
        opened={formOpened}
        size="md"
        padding="lg"
        onClose={() => {
          setFormOpened(false);
        }}
        withCloseButton>
        <form
          onSubmit={form.onSubmit(async (values) => save(values))}
          style={{ width: '100%', display: 'flex' }}>
          <LiitFormContainer
            confirmText={t`Spara`}
            onCancel={() => {
              setFormOpened(false);
            }}>
            <Stack>
              <TextInput label={t`Namn`} {...form.getInputProps('name')} maxLength={100} />
              <Input.Wrapper label="Färg">
                <Stack gap={'xs'}>
                  <Group justify={'start'} gap="xs">
                    {getSwatches(2)}
                  </Group>
                  <Group justify={'start'} gap="xs">
                    {getSwatches(4)}
                  </Group>
                  <Group justify={'start'} gap="xs">
                    {getSwatches(6)}
                  </Group>
                </Stack>
              </Input.Wrapper>
            </Stack>
          </LiitFormContainer>
        </form>
      </LiitDrawer>
    </GrantMeContainer>
  );
};

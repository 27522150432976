import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Group, Stack, Title, useMantineTheme, Divider } from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { ApplicationItem, ApplicationQueryType } from '../../../models/ApplicationItem';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { ReviewApplicationViewColumns } from './ReviewApplicationListColumns';
import { setColorsByDistinctId } from '../../../components/LiitGrid/SetColorsByDistinctId';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { PagedResult, PaginationModel } from '../../../models/PagedResult';
import { isMissing } from 'utilitype';
import { IconCoffee } from '@tabler/icons-react';
import { ReviewInformation } from '../../common/application/ReviewInformation';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { t } from 'ttag';
import { ReviewApplicationListPrintButton } from './ReviewApplicationListPrintButton';
import { TableState } from '../../../components/LiitGrid/TableSortProps';
import { useApplicationQueryParameters } from '../../../hooks/useApplicationQueryParameters';
import { Tag } from '../../../models/Tag';
import { createStyles } from '@mantine/emotion';
import { BORDER_COLOR } from './ApplicationsList';
import { LiitMultiSelectDropdown } from '../../../components/LiitMultiSelectDropdown/LiitMulltiSelectDropdown';

export const styles = createStyles((theme) => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ReviewApplicationList: React.FC = () => {
  const [reviewablePeriods, setReviewablePeriods] = useState<LiitDropdownMenuItem[] | null>(null);
  const [applications, setApplications] = useState<PagedResult<ApplicationItem> | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [tags, setTags] = useState<Tag[]>();
  const [isFetching, setIsFetching] = useState(true);
  const theme = useMantineTheme();
  const { classes } = styles();
  const [parameters, setParameters] = useApplicationQueryParameters();
  const [isInitialized, setIsInitialized] = useState(false);

  const setColorsbyFormId = (items: ApplicationItem[], periods: LiitDropdownMenuItem[]) => {
    items.forEach((application) => {
      const { applicationPeriodId } = application;
      const colorItem = periods.find((p) => p.id === applicationPeriodId);
      application.categoryColor = {
        color: colorItem?.color ?? 'white',
        inverted: colorItem?.inverted ?? 'black',
      };
    });
  };

  const initialize = async () => {
    if (isMissing(selectedOrganisation)) {
      return;
    }

    const reviewable = await api.getReviewablePeriods(selectedOrganisation.id);

    const colors = setColorsByDistinctId(
      theme,
      reviewable.map((r) => r.periodId),
    );

    setReviewablePeriods(
      reviewable.map((r) => {
        return {
          id: r.periodId,
          text: r.title,
          color: colors[r.periodId].color,
          inverted: colors[r.periodId].inverted,
        };
      }),
    );

    const tagsResponse = await api.getTags(selectedOrganisation!.id);
    setTags(tagsResponse);

    setIsInitialized(true);
  };

  const fetchApplications = async () => {
    if (!selectedOrganisation || reviewablePeriods === null) {
      return;
    }

    if (
      parameters.applicationPeriodId &&
      !reviewablePeriods.some((rp) => rp.id === parameters.applicationPeriodId)
    ) {
      // setReviewFilterPeriod(null);
      return;
    }

    setIsFetching(true);

    const response = await api.queryApplications(selectedOrganisation.id, parameters);

    const applicationList = response.data;
    const p = {} as PagedResult<any>;
    p.data = applicationList;
    p.pagination = {
      currentPage: parameters.page,
      totalCount: response.count,
      totalPages: Math.ceil(response.count / parameters.pageSize),
    } as PaginationModel;

    setColorsbyFormId(applicationList, reviewablePeriods);
    setApplications(p);

    setIsFetching(false);
  };

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);

      if (isInitialized) {
        await fetchApplications();
      } else {
        await initialize();
      }

      setIsFetching(false);
    };

    init();
  }, [parameters, isInitialized]);

  const getPeriodName = () => {
    if (reviewablePeriods && parameters.applicationPeriodId) {
      return reviewablePeriods.find((x) => x.id === parameters.applicationPeriodId)?.text;
    }
    return null;
  };

  const getPageTitle = () => {
    if (parameters.type === ApplicationQueryType.ToReview) {
      return 'Ej granskade ansökningar';
    } else if (parameters.type === ApplicationQueryType.Reviewed) {
      return 'Granskade ansökningar';
    } else {
      return '';
    }
  };

  const getEmptyText = () => {
    if (parameters.type === ApplicationQueryType.ToReview) {
      return 'Bra jobbat! Alla ansökningar är granskade. Dags för en kaffe.';
    } else if (parameters.type === ApplicationQueryType.Reviewed) {
      return 'Vi kunde inte hitta några granskade ansökningar';
    } else {
      return '';
    }
  };

  const getEmptyIcon = () => {
    if (parameters.type === ApplicationQueryType.ToReview) {
      return IconCoffee;
    } else {
      return undefined;
    }
  };

  const hasTags = (): boolean => {
    return tags && tags.length > 0 ? true : false;
  };

  return (
    <GrantMeContainer takeAllSpace={true} scrollable={false} style={{ overflow: 'hidden' }}>
      <Stack gap={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{getPageTitle()}</Title>
          <ReviewApplicationListPrintButton
            title={getPageTitle()}
            periodName={getPeriodName()}
            applications={applications?.data}
          />
        </GrantMeTitleContainer>
        <Group pl={42} className={classes.filterGroup} gap={'xl'} h={48}>
          <LiitDropdown
            emptyText={t`Alla perioder`}
            menuItems={reviewablePeriods}
            onChange={(periodId) => {
              const p = { ...parameters, applicationPeriodId: periodId, page: 1 };
              setParameters(p);
            }}
            selectedId={parameters.applicationPeriodId}
            showColorBox={true}
          />
          {tags && tags.length > 0 && (
            <>
              <Divider orientation="vertical" />
              <LiitMultiSelectDropdown
                itemsDescription={t`kategorier`}
                emptyText={t`Alla kategorier`}
                showColorBox={true}
                menuItems={tags.map((x) => ({ id: x.id, text: x.name, color: x.color }))}
                selectedIds={parameters.tagIds as string[]}
                onChange={(idArray) => {
                  setParameters({ ...parameters, tagIds: idArray, page: 1 });
                }}
              />
            </>
          )}
        </Group>
        <ReviewInformation filterOnId={parameters.applicationPeriodId} />
        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={applications}
            columnInfo={ReviewApplicationViewColumns(hasTags())}
            emptyText={getEmptyText()}
            emptyIcon={getEmptyIcon()}
            onRowClick={(id: string) => {
              navigate(id);
            }}
            fetchingData={isFetching}
            onPageChange={(ts: TableState) => {
              const p = { ...parameters, page: ts.page };
              setParameters(p);
            }}
            onSortChange={(ts: TableState) => {
              const p = { ...parameters, orderBy: ts.orderBy, isAscending: ts.isAscending };
              setParameters(p);
            }}
            isPrintable={true}
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};

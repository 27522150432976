import {
  Button,
  Group,
  Stack,
  Textarea,
  Text,
  Input,
  useMantineTheme,
  Select,
  Tooltip,
  Box,
  Title,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { t } from 'ttag';
import { isMissing, isPresent } from 'utilitype';
import { useApi } from '../../hooks/useApi';
import {
  CONTAINER_PADDING_TOP,
  CONTAINER_PADDING_WIDTH,
  MAX_FORM_WIDTH,
} from '../../layout/Layout';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { ApplicationPeriodType } from '../../models/ApplicationPeriodType';
import { ErrorMessages } from '../../utils/errorMessages';
import { showSuccessNotification } from '../../utils/notificationHelper';
import {
  ApplicationPeriodFormValues,
  applicationPeriodToFormValues,
} from './ApplicationPeriodToFormValues';
import {
  checkForParameters,
  EmailParameterInformation,
  EmailParameters,
} from './EmailTextsParameters';
import { Organisation } from '../../models/Organisation';

type EmailTextObject = {
  listKey: string;
  bodyPropertyName: string;
  subjectPropertyName: string;
  description: string;
  title: string;
  parameters: EmailParameters[];
};

enum EmailTextType {
  ApplicationRecieved = 0,
  ApplicationApproved = 1,
  ApplicationRejected = 2,
  RequisitionReminder = 3,
  CompletionRequested = 4,
  CompletionReceived = 5,
}

const EmailTexts: EmailTextObject[] = [
  {
    listKey: 'ApplicationRecieved',
    bodyPropertyName: 'mailTextApplicationReceived',
    subjectPropertyName: 'mailSubjectApplicationReceived',
    description: t`Detta e-post skickas ut när en ansökan skickas in`,
    title: 'Ansökan mottagen',
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.Organisation,
    ],
  },
  {
    listKey: 'ApplicationApproved',
    bodyPropertyName: 'mailTextApplicationApproved',
    subjectPropertyName: 'mailSubjectApplicationApproved',
    description: t`Detta e-post skickas ut när en ansökan har blivit beviljad`,
    title: t`Ansökan beviljad`,
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.GrantedAmount,
      EmailParameters.Organisation,
    ],
  },
  {
    listKey: 'ApplicationRejected',
    bodyPropertyName: 'mailTextApplicationRejected',
    subjectPropertyName: 'mailSubjectApplicationRejected',
    description: t`Detta e-post skickas ut när en ansökan har blivit avslagen`,
    title: t`Ansökan avslagen`,
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.Organisation,
    ],
  },
  {
    listKey: 'RequisitionReminder',
    bodyPropertyName: 'mailTextRequisitionReminder',
    subjectPropertyName: 'mailSubjectRequisitionReminder',
    description: t`Detta e-post skickas ut när det är dags att rekvirera pengar`,
    title: t`Rekvirering påminelse`,
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.GrantedAmount,
      EmailParameters.Organisation,
    ],
  },
  {
    listKey: 'CompletionRequested',
    bodyPropertyName: 'mailTextCompletionRequested',
    subjectPropertyName: 'mailSubjectCompletionRequested',
    description: t`Detta e-post skickas ut när en granskare begäre en komplettering på en ansökan`,
    title: t`Komplettering begärd`,
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.Organisation,
    ],
  },
  {
    listKey: 'CompletionReceived',
    bodyPropertyName: 'mailTextCompletionReceived',
    subjectPropertyName: 'mailSubjectCompletionReceived',
    description: t`Detta e-post skickas till ansökaren när en komplettering mottagits`,
    title: t`Komplettering mottagen`,
    parameters: [
      EmailParameters.FirstName,
      EmailParameters.LastMame,
      EmailParameters.PeriodName,
      EmailParameters.ApplicationNumber,
      EmailParameters.Title,
      EmailParameters.Organisation,
    ],
  },
];

interface PeriodEmailTextsProps {
  applicationPeriod: ApplicationPeriod;
  selectedOrganisation: Organisation;
  onSave: () => void;
}

export const PeriodEmailTexts: React.FC<PeriodEmailTextsProps> = ({
  applicationPeriod,
  selectedOrganisation,
  onSave,
}) => {
  const [templateToEdit, setTemplateToEdit] = useState<EmailTextObject>(EmailTexts[0]);
  const theme = useMantineTheme();
  const inputRefs = useRef<{ [key: string]: HTMLInputElement | HTMLTextAreaElement | null }>({});
  const [activeInput, setActiveInput] = useState<string | null>(null);

  const { isArchived } = applicationPeriod;

  const form = useForm<ApplicationPeriodFormValues>({
    validateInputOnBlur: true,
    initialValues: applicationPeriodToFormValues(
      applicationPeriod,
      selectedOrganisation.productVariant,
    ),
    validate: {
      mailTextApplicationReceived: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailTextApplicationApproved: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailTextApplicationRejected: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailTextRequisitionReminder: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailTextCompletionRequested: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailTextCompletionReceived: (value) => (!isPresent(value) ? ErrorMessages.IS_REQUIRED : null),
      mailSubjectApplicationApproved: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailSubjectApplicationReceived: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailSubjectApplicationRejected: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailSubjectRequisitionReminder: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailSubjectCompletionRequested: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
      mailSubjectCompletionReceived: (value) =>
        !isPresent(value) ? ErrorMessages.IS_REQUIRED : null,
    },
  });

  const api = useApi();

  const save = async (values: ApplicationPeriodFormValues) => {
    if (isMissing(selectedOrganisation)) {
      return;
    }

    const { typeSelectValue, ...rest } = values;

    const saveApplicationPeriod = {
      ...rest,
      startDate: DateTime.fromJSDate(values.startDate).toFormat('yyyy-MM-dd'),
      endDate: values.endDate ? DateTime.fromJSDate(values.endDate).toFormat('yyyy-MM-dd') : null,
      type: Number(typeSelectValue) as ApplicationPeriodType,
    };

    const response = await api.updateApplicationPeriod(
      selectedOrganisation.id,
      saveApplicationPeriod,
    );
    if (response.ok) {
      showSuccessNotification(t`Eposttexter sparade`, t`Texter för epost sparade`);
      onSave();
    }
  };

  const getTextStuff = (text: string) => {
    const textWithParameters = checkForParameters(text);

    return (
      <Text size={'sm'} style={{ whiteSpace: 'pre-wrap' }}>
        {textWithParameters.map((textItem, index) => (
          <Text key={'text_' + index} span color={textItem.color}>
            {textItem.text}
          </Text>
        ))}
      </Text>
    );
  };

  const handleFocus = (inputName: string) => {
    setActiveInput(inputName);
  };

  const insertAtCursor = (text: string) => {
    if (isMissing(activeInput)) return;

    const input = inputRefs.current[activeInput];
    if (isMissing(input)) return;

    const startPosition = input.selectionStart || 0;
    const endPosition = input.selectionEnd || 0;

    const newValue =
      input.value.substring(0, startPosition) +
      text +
      input.value.substring(endPosition);

    form.setFieldValue(templateToEdit[activeInput === 'subjectInput' ? 'subjectPropertyName' : 'bodyPropertyName'], newValue);

    requestAnimationFrame(() => {
      input.selectionStart = input.selectionEnd = startPosition + text.length;
      input.focus();
    });
  };

  return (
    <Stack
      pl={CONTAINER_PADDING_WIDTH}
      pr={CONTAINER_PADDING_WIDTH}
      pt={CONTAINER_PADDING_TOP}
      maw={MAX_FORM_WIDTH}>
      <Select
        label={t`E-Posttyp`}
        value={templateToEdit.listKey}
        data={EmailTexts.map((x) => ({ label: x.title, value: x.listKey }))}
        onChange={(change) => {
          const textItem = EmailTexts.find((x) => x.listKey === change);
          if (!textItem) {
            return;
          }

          setTemplateToEdit(textItem);
        }}
      />

      <form onSubmit={form.onSubmit(async (values) => save(values as any))}>
        <Stack>
          <Title order={4}>{templateToEdit.title}</Title>
          <Text size='sm'>{templateToEdit.description}</Text>
          <TextInput
            onFocusCapture={() => {
              handleFocus('subjectInput');
            }}
            ref={(el) => (inputRefs.current.subjectInput = el)}
            label={t`Ämne`}
            w={'100%'}
            {...form.getInputProps(templateToEdit.subjectPropertyName)}>

          </TextInput>
          <Textarea
            onFocusCapture={() => {
              handleFocus('bodyInput');
            }}
            ref={(el) => (inputRefs.current.bodyInput = el)}
            label={t`Innehåll`}
            disabled={isArchived}
            w={'100%'}
            autosize={true}
            minRows={4}
            {...form.getInputProps(templateToEdit.bodyPropertyName)}></Textarea>

          {!isArchived && (
            <Input.Wrapper label={t`Parametrar`}>
              <Group gap={0} pt={0} pb={16}>
                {templateToEdit.parameters.map((param) => {
                  return (
                    <Tooltip
                      color={theme.colors[theme.primaryColor][5]}
                      withArrow
                      key={param}
                      label={EmailParameterInformation[param].description}>
                      <Button
                        variant={'subtle'}
                        size={'xs'}
                        onClick={() => { insertAtCursor(EmailParameterInformation[param].parameter); }}>
                        {EmailParameterInformation[param].parameter}
                      </Button>
                    </Tooltip>
                  );
                })}
              </Group>
            </Input.Wrapper>
          )}
          <Title order={4}>{t`Förhandsgranskning`}</Title>
          <Stack>
            <Input.Wrapper label={t`Ämne`}>
              <Box p={16} style={{ backgroundColor: theme.colors.gray[1] }}>
                {getTextStuff(form.values[templateToEdit.subjectPropertyName])}
              </Box>
            </Input.Wrapper>
            <Input.Wrapper label={t`Innehåll`}>
              <Box p={16} style={{ backgroundColor: theme.colors.gray[1] }}>
                {getTextStuff(form.values[templateToEdit.bodyPropertyName])}
              </Box>
            </Input.Wrapper>
          </Stack>

        </Stack>
        {!isArchived && (
          <Group justify={'end'} pt={16}>
            <Button type={'submit'}>{t`Uppdatera E-posttexter`}</Button>
          </Group>
        )}
      </form>
    </Stack>
  );
};

import React, { ReactNode } from 'react';
import { applicationViewStyles } from './applicationViewStyles';
import { useScrollToTop } from '../../../hooks/useScrollToTop';

export const ScrollContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { ref } = useScrollToTop();
  const { classes } = applicationViewStyles();

  return (
    <div className={classes.scrollContainer} ref={ref}>
      {children}
    </div>
  );
};

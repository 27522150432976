import { createStyles } from '@mantine/emotion';

export const formPageBuilderStyles = createStyles((theme) => ({
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // borderRadius: theme.radius.md,
    border: `1px dashed ${
      theme.colors.gray[3]
    }`,
    padding: '8px 16px',
    backgroundColor: theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  elementTypeName: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%)',
    textAlign: 'center',
    border: '1px solid #e9ecef',
    borderTop: 'none',
    backgroundColor: '#f1f3f5',
    padding: '4px 16px',
    borderTopLeftRadius: '4px',
    borderRop0RightRadius: '4px',
  },
  elementControlls: {
    position: 'absolute',
    display: 'flex',
    top: 5,
    right: 10,
  },
  sizeControlls: {
    position: 'absolute',
    display: 'flex',
    bottom: 5,
    left: 15,
  },
  orderControlls: {
    position: 'absolute',
    display: 'flex',
    bottom: 5,
    right: 10,
  },
  elementInfo: {
    width: '100%',
    flex: 1,
  },
}));
import { Stack, Title, Text, Center } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { t } from 'ttag';

const RADIUS = 'sm';
const AUTO_CLOSE = 3000;

export const showSuccessNotification = (title: string | undefined, message: string | ReactNode) => {
  showNotification({
    title: title,
    message: message,
    color: 'green',
    icon: <IconCheck />,
    radius: RADIUS,
    autoClose: AUTO_CLOSE,
  });
};

export const showInformationNotification = (title: string | undefined, message: string | ReactNode) => {
  showNotification({
    title: title,
    message: message,
    icon: <IconInfoCircle />,
    radius: RADIUS,
    autoClose: AUTO_CLOSE,
  });
};

export const showFailNotification = (title: string | undefined, message: string | ReactNode) => {
  showNotification({
    title: title,
    message: message,
    color: 'red',
    icon: <IconX />,
    radius: RADIUS,
    autoClose: AUTO_CLOSE,
  });
};

export const showConfirmModal = (title: string, message: string, confirmButtonLabel: string, cancelButtonLabel: string, onConfirm: () => void, onCancel: () => void) => {
  return openConfirmModal({
    title: <Title order={4}>{title}</Title>,
    children: (
      <Text size={'sm'}>{message}</Text>
    ),
    withCloseButton: false,
    labels: { confirm: confirmButtonLabel, cancel: cancelButtonLabel },
    onConfirm: onConfirm,
    onCancel: onCancel,
  });
};

export const showConfirmCancelModal = (message: string, onConfirm: () => void) => {
  return showConfirmModal(t`Bekräfta`, message, t`OK`, t`Avbryt`, onConfirm, () => { });
};

export const showConfirmDeleteModal = (message: string, onConfirm: () => void) => {
  return showConfirmModal(t`Bekräfta borttagning`, message, t`Ta bort`, t`Avbryt`, onConfirm, () => { });
};
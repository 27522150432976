import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Group, Stack, Text } from '@mantine/core';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { t } from 'ttag';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { isPresent } from 'utilitype';
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  MS_POWERPOINT_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import { FileUpload } from '../../models/FileUpload';
import { createStyles } from '@mantine/emotion';

const ICON_SIZE = 52;

const useStyles = createStyles((theme) => ({
  dropZoneGroup: {
    position: 'relative',
    height: 'min-content',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: theme.radius.md,
    cursor: 'pointer',
    padding: theme.spacing.xl,
    '&[data-idle]': {
      borderColor: theme.colors.gray[3],
    },
    '&[data-reject]': {
      borderColor: theme.colors.red[6],
    },
    '&[data-accept]': {
      borderColor: theme.colors.blue[6],
    },
  },
}));

export const DocumentUploadDrawer: React.FC<{
  open: boolean;
  onClose: () => void;
  onFileUploaded: () => Promise<void>;
}> = ({ onClose, open, onFileUploaded }) => {
  const emptyGuid = '00000000-0000-0000-0000-000000000000';
  const { folderId = emptyGuid } = useParams();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const styles = useStyles();
  const openRef = useRef<() => void>(null);
  const [uploadState, setUploadState] = useState<'idle' | 'loading' | 'error'>('idle');

  const uploadFile = async (files: FileWithPath[]) => {
    if (isPresent(selectedOrganisation)) {
      setUploadState('loading');
      Promise.all(
        files.map(async (fileWithPath) => {
          return api.uploadDocumentStorageFile(
            selectedOrganisation.id,
            folderId,
            new FileUpload(
              fileWithPath.name,
              await fileWithPath.arrayBuffer(),
              fileWithPath.type,
              fileWithPath.size,
              async () => onFileUploaded(),
            ),
          );
        }),
      )
        .then(() => {
          setUploadState('idle');
          onClose();
        })
        .catch(() => setUploadState('error'));
    }
  };

  return (
    <LiitDrawer onClose={onClose} opened={open} titleText={t`Ladda upp fil`} size="lg" padding="lg">
      <Stack>
        <Dropzone
          openRef={openRef}
          onDrop={(files) => uploadFile(files)}
          maxSize={20 * 1024 ** 2}
          multiple={true}
          loading={uploadState === 'loading'}
          className={styles.classes.dropZoneGroup}
          accept={[
            ...IMAGE_MIME_TYPE,
            ...PDF_MIME_TYPE,
            ...MS_WORD_MIME_TYPE,
            ...MS_EXCEL_MIME_TYPE,
            ...MS_POWERPOINT_MIME_TYPE,
            'text/plain',
          ]}>
          <Group justify="center" gap="xl">
            <Dropzone.Accept>
              <IconUpload size={ICON_SIZE} color="var(--mantine-color-blue-6)" stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={ICON_SIZE} color="var(--mantine-color-red-6)" stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size={ICON_SIZE} color="var(--mantine-color-dimmed)" stroke={1.5} />
            </Dropzone.Idle>

            <Stack gap="md">
              <Stack gap="xs">
                <Text size="xl" inline ta="center">
                  {t`Klicka här`} {t`eller dra och släpp filer för att ladda upp`}
                </Text>
                <Text size="sm" c="dimmed" inline mt={7} ta="center">
                  {t`Lägg till en eller fler men varje fil får inte överstiga 20 mb`}
                </Text>
                {uploadState === 'error' ? (
                  <Text
                    inline
                    size="sm"
                    ta="center"
                    c="var(--mantine-color-red-6)">{t`Kunde inte ladda upp fil. Vänligen försök igen`}</Text>
                ) : null}
              </Stack>
              <Group justify="center">
                <Button
                  onClick={() => openRef.current?.()}
                  style={{ pointerEvents: 'all' }}>{t`Välj filer`}</Button>
              </Group>
            </Stack>
          </Group>
        </Dropzone>
      </Stack>
    </LiitDrawer>
  );
};

import {
  DirectionProvider,
  MantineProvider,
  MantineThemeOverride,
  rem,
  Table,
} from '@mantine/core';
import { Router } from './Router';
import { customColors } from './utils/customColors';
import { getLocale, setLocale } from './i18nInit';
import { HEADER_HEIGHT } from './layout/MenuHeader';
import { ModalsProvider } from '@mantine/modals';
import { SessionProvider } from './providers/SessionProvider';
import { MantineEmotionProvider } from '@mantine/emotion';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { Locals } from './locals';
import { BORDER_COLOR } from './theme';


export const TOP_PADDING = 32;
export const INPUT_MAX_LENGTH = 200;

const App = () => {
  setLocale(Locals.sv);

  const fontFamily = 'Inter Tight, sans-serif';

  const customTheme: MantineThemeOverride = {
    colors: customColors,
    primaryColor: 'blue',
    primaryShade: { light: 5, dark: 4 },
    defaultRadius: 'sm',
    defaultGradient: {
      from: 'orange',
      to: 'red',
      deg: 45,
    },
    fontFamily: fontFamily,
    headings: { fontFamily: fontFamily },
    components: {
      Accordion: {
        styles: (theme) => ({
          label: {
            fontSize: theme.fontSizes.md,
            color: theme.colors.gray[9],
            fontWeight: 600,
          },
        }),
      },
      Tabs: {
        styles: (theme) => ({
          tab: {
            color: theme.colors.gray[9],
            fontWeight: 600,
            '&[dataActive]': {
              color: theme.colors[theme.primaryColor][7],
            },
          },
          tabsList: {
            border: '0px',
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            color: theme.colors.gray[9],
            fontWeight: 'bold',
            display: 'inline-flex',
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: {
            color: theme.colors.gray[9],
            fontWeight: 'bold',
            display: 'inline-flex',
            gap: '8px',
            width: '100%',
          },
        }),
      },
      Table: Table.extend({
        vars: (theme) => {
          return {
            table: {},
            th: {
              background: theme.colors.gray[1],
              color: theme.colors.gray[9],
            },
            td: {
              '&:hover': {
                backgroundColor: 'red',
                borderBottom: `1px solid ${BORDER_COLOR}`,
              },
            },
          };
        },
      }),

      Divider: {
        styles: (theme) => ({
          root: {
            borderTopColor: `${theme.colors.gray[3]} !important`,
            borderLeftColor: `${theme.colors.gray[3]} !important`,
          },
        }),
      },

      Paper: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        }),
      },

      Button: {
        styles: {
          label: {
            textTransform: 'uppercase',
          },
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      ActionIcon: {
        styles: {
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      Title: {
        styles: (theme) => ({
          root: {
            color: theme.colors.gray[9],
            //textTransform: 'uppercase',
            fontFamily: fontFamily,
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamily,
            },
          },
        }),
      },

      Text: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamily,
            },
          },
        }),
      },
    },
  };

  return (
    <MantineProvider theme={customTheme}>
      <Notifications position="top-center" />
      <MantineEmotionProvider>
        <DirectionProvider>
          <DatesProvider settings={{ locale: getLocale().name }}>
            <ModalsProvider>
              <SessionProvider>
                <Router />
              </SessionProvider>
            </ModalsProvider>
          </DatesProvider>
        </DirectionProvider>
      </MantineEmotionProvider>
    </MantineProvider>
  );
};

export default App;

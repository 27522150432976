import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { ReviewApplicationList } from './features/application/application-list/ReviewApplicationList';
import { ApplicationPeriodList } from './features/period/application-period-list/ApplicationPeriodList';
import { ApplicationView } from './features/application/application-view/ApplicationView';
import { Layout } from './layout/Layout';
import { DashboardView } from './features/start/DashboardView';
import { PageNotFound } from './layout/PageNotFound';
import { OrganisationsView } from './features/organisations/OrganisationsView';
import { ApplicationPeriodView } from './features/period/ApplicationPeriodView';
import { ApprovalPeriodApplicationList } from './features/application-approval/application-approval-list/ApprovalApplicationList';
import { InvitationView } from './features/invitation/InvitationView';
import { ArchiveApplicationPeriodList } from './features/archive/list/ArchiveApplicationPeriodList';
import { ApprovalApplicationPeriodList } from './features/application-approval/application-approval-list/ApprovalApplicationPeriodList';
import { ArchiveApplicationList } from './features/archive/list/ArchiveApplicationList';
import { UnclaimedList } from './features/payments/UnclaimedList';
import { ApplicationReportList } from './features/application-reports/ApplicationReportList';
import { LoginView } from './features/start/LoginView';
import { useSession } from './hooks/useSession';
import { LogoutView } from './features/start/LogoutView';
import { SettingsGeneralView } from './features/settings/SettingsGeneralView';
import { SettingsCriteriasView } from './features/settings/SettingsCriteriasView';
import { SettingsUsersView } from './features/settings/SettingsUsersView';
import { Agreement } from './features/agreement/Agreement';
import { SettingsBankAccountsView } from './features/settings/SettingsBankAccountsView';
import { PaymentBatchesView } from './features/payments/PaymentBatchesView';
import { UnpaidList } from './features/payments/UnpaidList';
import { PaymentBatchView } from './features/payments/PaymentBatchView';
import { ApplicationsList } from './features/application/application-list/ApplicationsList';
import { SettingsTagsView } from './features/settings/SettingsTagsView';
import { FormsView } from './features/settings/forms/FormsView';
import { FormView } from './features/settings/forms/FormView';
import { UserProfileView } from './features/user/UserProfileView';
import { DocumentsList } from './features/document-storage/DocumentsList';

export const Router: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const session = useSession();
  const { selectedOrganisation, agreementsToApprove, sessionFetched } = session;

  if (isAuthenticated) {
    if (!sessionFetched) {
      return null;
    }

    if (agreementsToApprove) {
      return (
        <Routes>
          <Route path="*" element={<Layout loadNavBar={false} />}>
            <Route path="*" element={<Agreement />} />
          </Route>
        </Routes>
      );
    } else if (selectedOrganisation) {
      return (
        <Routes>
          <Route path="/logout" element={<LogoutView />} />
          <Route path="/" element={<Layout loadNavBar={true} />}>
            <Route path="" element={<DashboardView />} />
            <Route
              path="applicationperiods"
              element={<ApplicationPeriodList />}
            />
            <Route
              path="applicationperiods/:applicationPeriodId"
              element={<ApplicationPeriodView />}
            />
            <Route
              path="reviews/todo"
              element={<ReviewApplicationList key="reviewstodo" />}
            />

            <Route
              path="reviews/completed"
              element={<ReviewApplicationList key="reviewscompleted" />}
            />

            <Route path="applications" element={<ApplicationsList />} />
            <Route path="search" element={<ApplicationsList key={'searchapplications'} />} />
            <Route path="approvals" element={<ApprovalApplicationPeriodList />} />
            <Route path="approvals/:applicationPeriodId" element={<ApprovalPeriodApplicationList />} />

            <Route path="applications/:applicationId" element={<ApplicationView />} />
            <Route path="payments/unpaid/:applicationId" element={<ApplicationView />} />
            <Route path="reviews/todo/:applicationId" element={<ApplicationView />} />
            <Route path="reviews/completed/:applicationId" element={<ApplicationView />} />
            <Route path="search/:applicationId" element={<ApplicationView />} />
            <Route path="approvals/:applicationPeriodId/:applicationId" element={<ApplicationView />} />
            <Route path="archive/applicationperiods/:applicationPeriodId/:applicationId" element={<ApplicationView />} />

            <Route path="payments/unpaid" element={<UnpaidList />} />
            <Route path="payments/unclaimed" element={<UnclaimedList />} />
            <Route path="paymentbatches" element={<PaymentBatchesView />} />
            <Route path="paymentbatches/:paymentBatchId" element={<PaymentBatchView />} />
            <Route path="paymentbatches/:paymentBatchId/:applicationId" element={<ApplicationView />} />
            <Route path="organisations" element={<OrganisationsView />} />
            <Route path="settings/general" element={<SettingsGeneralView />} />
            <Route path="settings/criterias" element={<SettingsCriteriasView />} />
            <Route path="settings/forms" element={<FormsView />} />
            <Route path="settings/forms/:formId" element={<FormView />} />
            <Route path="settings/categories" element={<SettingsTagsView />} />
            <Route path="settings/users" element={<SettingsUsersView />} />
            <Route path="settings/bankaccounts" element={<SettingsBankAccountsView />} />
            <Route path="applicationreports" element={<ApplicationReportList />} />
            <Route path="applicationreports/:applicationId" element={<ApplicationView />} />
            <Route path="user/profile" element={<UserProfileView />} />

            <Route path="archive" element={<ArchiveApplicationPeriodList />} />
            <Route
              path="archive/applicationperiods/:applicationPeriodId"
              element={<ArchiveApplicationList />}
            />

            <Route path="documents" element={<DocumentsList />} />
            <Route path="documents/:folderId" element={<DocumentsList />} />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/logout" element={<LogoutView />} />
          <Route path="*" element={<Layout loadNavBar={false} />}>
            <Route path="*" element={<OrganisationsView />} />
          </Route>
        </Routes>
      );
    }
  }

  return (
    <Routes>
      <Route path="invitations" element={<Layout loadNavBar={false} />}>
        <Route path=":invitationKey" element={<InvitationView />} />
      </Route>
      <Route path="*" element={<LoginView />} />
    </Routes>
  );
};

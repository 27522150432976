import { ReactNode } from 'react';
import { formatValue } from '../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { RenderCell, CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { Group, Text } from '@mantine/core';
import {
  IconFileFilled,
  IconFilePower,
  IconFileSpreadsheet,
  IconFileText,
  IconFileTypePdf,
  IconFileWord,
  IconFoldersFilled,
} from '@tabler/icons-react';
import { FolderContentType } from '../../models/FolderContentType';

export const GetDocumentIcon = (type: FolderContentType): ReactNode => {
  switch (type) {
    case FolderContentType.Spreadsheet:
      return <IconFileSpreadsheet />;

    case FolderContentType.Word:
      return (<IconFileWord />);

    case FolderContentType.Folder:
      return <IconFoldersFilled />;

    case FolderContentType.Pdf:
      return <IconFileTypePdf />;

    case FolderContentType.Presentations:
      return <IconFilePower />;

    case FolderContentType.Text:
      return <IconFileText />;

    case FolderContentType.Images:
    case FolderContentType.File:
    default:
      return <IconFileFilled />;
  }
};

export const RenderDocumentsNameCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, rowValues } = rowInformation;

  return (
    <Group>
      {GetDocumentIcon(rowValues.type)}
      <Text size='sm'>{formatValue(cellValue, PropertyType.Text)}</Text>
    </Group>
  );
};

import { useMantineTheme, ActionIcon, Group, Tooltip, Image } from '@mantine/core';
import { FC, useState, useRef, useEffect, MouseEventHandler, TouchEventHandler } from 'react';
import { isPresent } from 'utilitype';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { PDFViewer } from '../../components/PdfViewer/PDFViewer';
import { useStyles } from './Styles';
import { FolderContentType } from '../../models/FolderContentType';
import { download } from '../../utils/download';
import { toBlob } from '../../utils/toBlob';
import { motion } from 'framer-motion';
import { IconX, IconDownload } from '@tabler/icons-react';
import { t } from 'ttag';
import { PDFViewerControls } from '../../components/PdfViewer/PDFViewerControls';
import cls from 'classnames';
import { FolderContent } from '../../models/FolderContent';

type DocumentPreviewerProps = {
  downloadArrayBuffer: () => Promise<ArrayBuffer>;
  previewOpened: boolean;
  closePreview: () => void;
  activeItem: FolderContent;
};

export const DocumentPreviewer: FC<DocumentPreviewerProps> = ({ 
  downloadArrayBuffer, 
  previewOpened, 
  closePreview, 
  activeItem,
}) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const PLACEHOLDER_IMAGE = '/img-placeholder.jpg';
  const PADDING_SPACE = 24;
  const DRAWER_WIDTH = 800 + PADDING_SPACE;
  const PDF_CONTENT_TYPE = 'application/pdf';
  const ZOOM_STEP = 0.2;
  const ZOOM_MIN = 1.0;
  const ZOOM_MAX = 2.6;
  const HIDE_BUTTONS_TIMER = 1500;
  const BUTTON_SIZE = 'lg';
  const [showButtons, setShowButtons] = useState(true);
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [scale, setScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);


  const getDownloadData = async (): Promise<string | null> => {
    const arrayBuffer = await downloadArrayBuffer();

    return URL.createObjectURL(toBlob(new Uint8Array(arrayBuffer), PDF_CONTENT_TYPE));
  };

  const triggerDownload = async () => {
    if (!activeItem || !activeItem.name) {
      return;
    }

    const downloadData = await downloadArrayBuffer();

    if (isPresent(downloadData)) {
      await download(downloadData, activeItem.name);
    }
  };

  useEffect(() => {
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);

    return () => clearTimeout(timer.current ?? undefined);
  }, []);

  const mouseMove: MouseEventHandler = () => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    if (!mouseOverButton) {
      timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
    }
  };

  const touchMe: TouchEventHandler = () => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
  };

  return (
    <LiitDrawer
      opened={isPresent(previewOpened)}
      onClose={closePreview}
      padding={0}
      size={DRAWER_WIDTH}>
      <div onMouseMove={mouseMove} onTouchStart={touchMe} className={classes.pdfWrapper}>
        {activeItem.type === FolderContentType.Pdf && (
          <PDFViewer
            scale={scale}
            rotation={rotation}
            getData={getDownloadData}
            filename={activeItem.name}
            pdfId={activeItem.id}
          />
        )}
        {activeItem.type === FolderContentType.Images && (
          <Image
            src={
              activeItem.previewUrl ??
              PLACEHOLDER_IMAGE
            }
            p={24}></Image>
        )}

        <motion.div
          animate={showButtons ? 'show' : 'hidden'}
          variants={{
            show: { opacity: 1, transition: { duration: 0.1 } },
            hidden: { opacity: 0, transition: { duration: 1 } },
          }}>
          <div
            className={classes.controllsClose}
            onMouseEnter={() => setMouseOverButton(true)}
            onMouseLeave={() => setMouseOverButton(false)}>
            <ActionIcon
              color={'gray.7'}
              variant={'subtle'}
              size={BUTTON_SIZE}
              onClick={() => closePreview()}>
              <IconX />
            </ActionIcon>
          </div>
          <div
            className={classes.controllsDownload}
            onMouseEnter={() => setMouseOverButton(true)}
            onMouseLeave={() => setMouseOverButton(false)}>
            <Tooltip label={t`Ladda ner`} position={'left'} offset={16}>
              <ActionIcon
                color={theme.primaryColor}
                variant={'subtle'}
                size={BUTTON_SIZE}
                onClick={() => triggerDownload()}>
                <IconDownload />
              </ActionIcon>
            </Tooltip>
          </div>

          {activeItem.type === FolderContentType.Pdf && (
            <div
              className={cls(classes.controlls)}
              onMouseEnter={() => setMouseOverButton(true)}
              onMouseLeave={() => setMouseOverButton(false)}>
              <Group justify={'center'}>
                <div className={classes.controllPanel}>
                  <PDFViewerControls
                    buttonSize={BUTTON_SIZE}
                    onRotateLeft={() => setRotation((prev) => (prev % 360) - 90)}
                    onRotateRight={() => setRotation((prev) => (prev % 360) + 90)}
                    onZoomOut={() =>
                      setScale((prev) => (prev > ZOOM_MIN ? prev - ZOOM_STEP : prev))
                    }
                    onZoomIn={() =>
                      setScale((prev) => (prev <= ZOOM_MAX ? prev + ZOOM_STEP : prev))
                    }
                    onZoomReset={() => setScale(1.0)}
                    disableZoomOut={scale <= ZOOM_MIN}
                    disableZoomIn={scale >= ZOOM_MAX}
                    disableZoomReset={scale === 1.0}
                  />
                </div>
              </Group>
            </div>
          )}
        </motion.div>
      </div>
    </LiitDrawer>
  );
};
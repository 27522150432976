import { createStyles } from '@mantine/emotion';

export const formBuilderStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '20% 60% 20% auto',
    gridTemplateRows: '80% 20%',
    gridRowEnd: 'auto',
    justifyItems: 'stretch',
  },
}));
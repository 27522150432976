import {
  Button,
  Group,
  Stack,
  Title,
  Text,
  Anchor,
  Flex,
  Menu,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { t } from 'ttag';
import { BackButton } from '../../../components/BackButton';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { Routes } from '../../../layout/Routes';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApprovalApplicationListColumns } from './ApprovalApplicationListColumns';
import { ApprovalApplicationPeriodCompletionModal } from './ApprovalApplicationPeriodCompletionModal';
import { ApprovalInformation } from './ApprovalInformation';
import { PeriodApprovalSummary } from './PeriodApprovalSummary';
import { DraftListPrintButton } from '../DraftListPrintButton';
import { createStyles } from '@mantine/emotion';
import { BORDER_COLOR } from '../../../theme';
import { Tag } from '../../../models/Tag';
import { LiitMultiSelectDropdown } from '../../../components/LiitMultiSelectDropdown/LiitMulltiSelectDropdown';
import { IconSettings } from '@tabler/icons-react';

export const styles = createStyles((theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.red[2],
    position: 'sticky',
    top: 0,
    zIndex: 50,
    alignItems: 'stretch',
    alignSelf: 'flex-start',
    width: '100%',
  },
}));

export interface ApplicationFilter {
  id: string;
  isActive: boolean;
  title: string;
  value: number;
  color: string;
}

export const ApprovalPeriodApplicationList: React.FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodApprovalSummary>();
  const [applications, setApplications] = useState<any[] | undefined>(undefined);
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [tags, setTags] = useState<Tag[]>();
  const [selectedTagIds, setSelectedTagIds] = useState<string[] | undefined>();
  const [selectedReviewerId, setSelectedReviewerId] = useState<string | null>();
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const { applicationPeriodId } = useParams();
  const navigate = useNavigate();
  const api = useApi();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { classes } = styles();

  const refreshApprovalPeriod = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const periodResult = await api.getApprovalPeriod(selectedOrganisation.id, applicationPeriodId);
    setSelectedPeriod(periodResult);
  };

  const refreshApprovalPeriodReviewers = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const result = await api.getApprovalPeriodReviewers(
      selectedOrganisation.id,
      applicationPeriodId,
    );
    setReviewers(result);
  };

  const refreshApprovalApplications = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const applicationsResult = await api.getApplicationsForApproval(
      selectedOrganisation.id,
      applicationPeriodId,
      selectedReviewerId,
      selectedTagIds,
    );

    setApplications(applicationsResult);
  };

  const refresh = async () => {
    await refreshApprovalApplications();
    await refreshApprovalPeriod();
    await refreshApprovalPeriodReviewers();

    const tagsResponse = await api.getTags(selectedOrganisation!.id);
    setTags(tagsResponse);    
  };

  const isArchived = () => {
    return selectedPeriod && selectedPeriod.archivedUtc;
  };

  const canMakeDecision = () => {
    return (
      applications &&
      applications.filter(
        (x) =>
          x.status === ApplicationStatus.PreliminaryApproved ||
          x.status === ApplicationStatus.PreliminaryDeclined,
      ).length > 0
    );
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refresh();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId) {
      fetchData();
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!selectedOrganisation || selectedReviewerId === undefined) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);
      await refreshApprovalApplications();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId) {
      fetchData();
    }
  }, [selectedReviewerId]);

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);
      await refreshApprovalApplications();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId && selectedTagIds) {
      fetchData();
    }
  }, [selectedTagIds]);  

  if (!selectedPeriod) {
    return null;
  }

  if (isArchived()) {
    return (
      <GrantMeContainer>
        <Group gap="xs" pb="xl">
          <BackButton route={Routes.Approvals} />
        </Group>

        <Text ta="center" pt="xl">
          Denna ansökningsperioden är arkiverad. Du hittar den under{' '}
          <Anchor component={Link} to={`/archive/applicationperiods/${selectedPeriod.id}`}>
            <b>Arkiv</b>
          </Anchor>
          .
        </Text>
      </GrantMeContainer>
    );
  }

  const getSuggestedAmountRightSection = () => {

    return <Group pl={'xs'} onClick={(e) => e.stopPropagation()}>
          <Menu trigger='hover' shadow="md" width={300}>
        <Menu.Target>
        <IconSettings size={14} />
        </Menu.Target>
  
        <Menu.Dropdown>
          <Menu.Label>Visa föreslaget belopp</Menu.Label>
          <Menu.Item  onClick={() => setSelectedReviewerId(null)}>
            som genomsnitt
          </Menu.Item>

          {reviewers.map((r) => {
            return <Menu.Item key={r.id} onClick={() => setSelectedReviewerId(r.id)}>
              av {r.firstname} {r.lastname}
            </Menu.Item>;
          })}

        </Menu.Dropdown>
      </Menu>
      </Group>;

  };

  return (
    <>
      <GrantMeContainer takeAllSpace={true} scrollable={false}>
        <Stack gap={'0'} style={{ flex: 1, overflow: 'hidden' }}>
          <GrantMeTitleContainer>
            <Group gap="xs" wrap={'nowrap'} style={{ flex: '1 1 auto', minWidth: 0 }}>
              <BackButton route={Routes.Approvals} />
              <Title
                pl={4}
                order={3}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {selectedPeriod?.title}
              </Title>
            </Group>

            {applications && selectedPeriod && (
              <DraftListPrintButton
                decision={null}
                disabled={false}
                period={selectedPeriod}
                applications={applications}
              />
            )}

            {selectedPeriod && (
              <Button
                disabled={!canMakeDecision()}
                size={'sm'}
                onClick={() => {
                  setIsModalOpened(true);
                }}>
                {t`Ta beslut`}
              </Button>
            )}
          </GrantMeTitleContainer>
          <ApprovalInformation period={selectedPeriod} />

          {tags && tags.length > 0 && (
            <Group justify={'space-between'} align={'center'} mih={48} h={48} pl={48} className={classes.bottomBorder}>
                <LiitMultiSelectDropdown
                  itemsDescription={t`kategorier`}
                  emptyText={t`Alla kategorier`}
                  showColorBox={true}
                  menuItems={tags.map((x) => ({ id: x.id, text: x.name, color: x.color }))}
                  selectedIds={selectedTagIds ?? []}
                  onChange={(idArray) => {
                    setSelectedTagIds(idArray);
                  }}
                />
            </Group>
          )}

          <Flex style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <LiitGrid
              data={applications}
              setData={setApplications}
              onCellValueChanged={async () => refreshApprovalPeriod()}
              columnInfo={ApprovalApplicationListColumns(selectedPeriod, tags && tags.length > 0 ? true : false, getSuggestedAmountRightSection())}
              onRowClick={(id: string) => {
                navigate(`/approvals/${applicationPeriodId}/${id}`);
              }}
              highligthOnHoover
              fetchingData={isFetching}
              startSort={'number'}
            />
          </Flex>
        </Stack>
      </GrantMeContainer>

      <ApprovalApplicationPeriodCompletionModal
        applicationPeriod={selectedPeriod}
        isOpened={isModalOpened}
        onClose={async (doRefresh: boolean) => {
          setIsModalOpened(false);
          if (doRefresh) {
            await refresh();
          }
        }}
      />
    </>
  );
};

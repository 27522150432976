import React, { useEffect, useState } from 'react';
import { Text, Button, Stack, TextInput, Title, useMantineTheme, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCopy, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { DisplayButtons } from '../../../components/LiitGrid/DisplayButtons';
import { LiitDrawer } from '../../../components/LiitDrawer/LiitDrawer';
import { t } from 'ttag';
import { LiitFormContainer } from '../../../components/LiitFormContainer/LiitFormContainer';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { showFailNotification, showSuccessNotification } from '../../../utils/notificationHelper';
import { openConfirmModal } from '@mantine/modals';
import { Form } from '../../../models/Form';
import { useNavigate } from 'react-router-dom';
import { PaymentOptionItems, PaymentOptions } from '../../../models/PaymentOptions';
import { ApplicantType, ApplicantTypeItems } from '../../../models/ApplicantType';
import { CellRenderInformation } from '../../../components/LiitGrid/RenderCell';
import { HoverTooltip } from '../../../components/HoverTooltip';
import { FormModel } from '../../../components/formbuilder/models/FormModel';
import { handleError } from '../../../utils/handleError';
import { isPresent } from 'utilitype';

interface FormValues {
  name: string;
  paymentOption: PaymentOptions;
  applicantType: ApplicantType;
  json: FormModel | null,
}

export const FormsView: React.FC = () => {
  const api = useApi();
  const [forms, setForms] = useState<Form[] | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const [isNew, setIsNew] = useState<boolean>(true);

  const form = useForm<FormValues>({
    initialValues: { name: '', paymentOption: PaymentOptions.Default, applicantType: ApplicantType.Person, json: null },
    transformValues: (values) => ({
      ...values,
      paymentOption: parseInt(values.paymentOption.toString()),
      applicantType: parseInt(values.applicantType.toString()),
    }),
  });

  const refreshForms = async () => {
    if (!selectedOrganisation) {
      return;
    }

    setIsFetching(true);
    const result = await api.getForms(selectedOrganisation.id);

    setForms(result);
    setIsFetching(false);
  };

  const newForm = async () => {
    form.reset();
    setIsNew(true);
    setDrawerOpened(true);
  };

  const editForm = async (id: string) => {

    setIsNew(false);
    const f = await api.getForm(selectedOrganisation!.id, id);
    if (f) {
      form.reset();
      form.setValues(f);
      setDrawerOpened(true);
    }
  };

  const copyForm = async (id: string) => {
    const formOld = await api.getForm(selectedOrganisation!.id, id);
    const name = `Kopia av ${formOld.name}`;
    const formNew = {
      applicantType: formOld.applicantType,
      paymentOption: formOld.paymentOption,
      json: formOld.json,
      name: name,
    };

    await api.createForm(selectedOrganisation!.id, formNew);
    await refreshForms();
    showSuccessNotification(undefined, t`Skapade formuläret '${name}'.`);
  };

  const gotoFormBuilder = (id: string) => {
    navigate(`/settings/forms/${id}`);
  };

  const deleteForm = async (id: string) => {
    if (!selectedOrganisation || !forms) {
      return;
    }

    const formToBeDeleted = forms.find(x => x.id === id);
    let confirmText = t`Vill du ta bort formuläret?`;

    if (isPresent(formToBeDeleted)) {
      confirmText = t`Vill du ta bort formuläret "${formToBeDeleted.name}"?`;
    }

    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{confirmText}</Text>
        </Stack>
      ),
      labels: { confirm: 'OK', cancel: 'Avbryt' },
      onConfirm: async () => {

        const response = await api.deleteForm(selectedOrganisation.id, id);
        if (response.ok) {
          await refreshForms();
        } else if (response.status === 409) {

          const periods = await response.json();
          const message = <>Formuläret kan inte tas bort eftersom det används på följande period(er):
            <Stack gap={0}>{periods.map(x => <Text size={'sm'} key={x}>- {x}</Text>)}</Stack></>;

          showFailNotification(undefined, message);
        } else {
          await handleError(response);
        }
      },
    });

  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshForms();
    };

    fetchData();
  }, [selectedOrganisation]);

  const save = async (values) => {
    if (!selectedOrganisation) {
      return;
    }

    if (isNew) {
      const response = await api.createForm(selectedOrganisation.id, values);

      if (response.ok) {
        const formResponse = await response.json();
        gotoFormBuilder(formResponse.id);
      } else {
        showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
      }
    } else {
      const response = await api.updateForm(selectedOrganisation.id, values);

      if (response.ok) {
        setDrawerOpened(false);
        await refreshForms();
      } else {
        showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
      }
    }
  };

  const displayColumns: IDisplayColumn[] = [
    {
      displayName: t`Namn`,
      propertyName: 'name',
      propertyType: PropertyType.Text,
      columnStyles: { paddingLeft: 40 },
    },
    {
      displayName: t`Målgrupp`,
      propertyName: 'applicantType',
      propertyType: PropertyType.Text,
      renderCell: (cellInformation: CellRenderInformation) => {
        return ApplicantTypeItems[cellInformation.cellValue];
      },
      // columnStyles: { width: '50%', cursor: 'pointer' },
    },
    {
      displayName: t`Utbetalningstyp`,
      propertyName: 'paymentOption',
      propertyType: PropertyType.Text,
      renderCell: (cellInformation: CellRenderInformation) => {
        return PaymentOptionItems[cellInformation.cellValue];
      },
    },
    {
      displayName: t`Senast ändrad`,
      propertyName: 'modifiedUtc',
      propertyType: PropertyType.Date,
    },
  ];

  const gridButtons: DisplayButtons[] = [
    { name: 'edit', icon: <IconEdit size={24} />, onClick: (id: string) => editForm(id), tooltip: t`Redigera namn` },
    { name: 'copy', icon: <IconCopy size={24} />, onClick: (id: string) => copyForm(id), tooltip: t`Kopiera` },
    { name: 'remove', icon: <IconTrash size={24} />, onClick: (id: string) => deleteForm(id), tooltip: t`Ta bort` },
  ];

  const theme = useMantineTheme();

  return (
    <GrantMeContainer>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Formulär`}</Title>
          <Button size={'sm'} onClick={() => newForm()} leftSection={<IconPlus />}>
            {t`Nytt formulär`}
          </Button>
        </GrantMeTitleContainer>

        <LiitGrid
          data={forms}
          columnInfo={displayColumns}
          displayButtons={gridButtons}
          fetchingData={isFetching}
          onRowClick={(id) => gotoFormBuilder(id)}
        />
      </Stack>
      <LiitDrawer
        title={
          <Title order={2}>Skapa nytt formulär</Title>
        }
        opened={drawerOpened}
        size="xl"
        onClose={() => {
          setDrawerOpened(false);
        }}
        withCloseButton>
        <form
          onSubmit={form.onSubmit(async (values) => save(values))}
          style={{ width: '100%', display: 'flex' }}>
          <LiitFormContainer
            confirmText={t`Spara`}
            onCancel={() => {
              setDrawerOpened(false);
            }}>
            <Stack>
              <TextInput label={t`Namn`} {...form.getInputProps('name')} maxLength={100} />

              {isNew &&
                <>
                  <Select
                    label={t`Målgrupp`}
                    withAsterisk
                    placeholder={t`Välj målgrupp`}
                    description={t`Observera att detta val inte går att ändra i efterhand.`}
                    {...form.getInputProps('applicantType')}
                    data={[
                      { value: ApplicantType.Person.toString(), label: ApplicantTypeItems[ApplicantType.Person] },
                      { value: ApplicantType.Organization.toString(), label: ApplicantTypeItems[ApplicantType.Organization] },
                    ]}
                  />

                  <Select
                    label={t`Utbetalningstyp`}
                    withAsterisk
                    description={t`Väl hur/om beviljade ansökningar ska utbetalas. Observera att detta val inte går att ändra i efterhand.`}
                    {...form.getInputProps('paymentOption')}
                    data={[
                      {
                        value: PaymentOptions.Default.toString(),
                        label: PaymentOptionItems[PaymentOptions.Default],
                      },
                      {
                        value: PaymentOptions.Requisition.toString(),
                        label: PaymentOptionItems[PaymentOptions.Requisition],
                      },
                      {
                        value: PaymentOptions.NoPayment.toString(),
                        label: PaymentOptionItems[PaymentOptions.NoPayment],
                      },
                    ]}
                  />
                </>
              }

            </Stack>
          </LiitFormContainer>
        </form>
      </LiitDrawer>
    </GrantMeContainer>
  );
};

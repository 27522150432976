import { useSessionStorage } from '@mantine/hooks';
import { useSession } from './useSession';
import { ApplicationQueryParameters, ApplicationQueryType } from '../models/ApplicationItem';
import { useLocation } from 'react-router-dom';
import { ApplicationStatus } from '../models/ApplicationStatus';

export const useApplicationQueryParameters = (): readonly [ApplicationQueryParameters, (val: ApplicationQueryParameters | ((prevState: ApplicationQueryParameters) => ApplicationQueryParameters)) => void, ApplicationQueryParameters] => {
  
  const { selectedOrganisation } = useSession();
  const location = useLocation();

  const getApplicationQueryTypeByLocation = () => {    

    if (location.pathname.startsWith('/applications')) {
      return ApplicationQueryType.All;
    } else if (location.pathname.startsWith('/reviews/todo')) {
      return ApplicationQueryType.ToReview;
    } else if (location.pathname.startsWith('/reviews/completed')) {
      return ApplicationQueryType.Reviewed;     
    } else if (location.pathname.startsWith('/approvals')) {
      return ApplicationQueryType.Approvals;
    } else if (location.pathname.startsWith('/archive')) {
      return ApplicationQueryType.Archive;        
    } else if (location.pathname.startsWith('/applicationreports')) {
      return ApplicationQueryType.Reports;       
    } else if (location.pathname.startsWith('/search')) {
      return ApplicationQueryType.Search;        
    } else if (location.pathname.startsWith('/payments/unpaid')) {
      return ApplicationQueryType.Unpaid;            
    } else if (location.pathname.startsWith('/paymentbatches')) {
      return ApplicationQueryType.Paid;                            
    } else {
      throw new Error('Unknown location: ' + location.pathname);
    }
  }; 

  const getSessionKeyByLocation = () => {
    const type = getApplicationQueryTypeByLocation();
    return `application_query_${selectedOrganisation!.id}_${type.toString()}`;
  };

  const getDefaultApplicationQueryParametersByLocation = () => {

    const type = getApplicationQueryTypeByLocation();
    switch (type) {
      case ApplicationQueryType.Reviewed:
        return { page: 1, pageSize: 50, isAscending: false, orderBy: 'number', type: type, statuses: [] };          

      case ApplicationQueryType.All:
        return { page: 1, pageSize: 50, isAscending: false, orderBy: 'number', type: type, statuses: [ApplicationStatus.Submitted, ApplicationStatus.Declined, ApplicationStatus.Approved].map((s) => s.toString()) };

      case ApplicationQueryType.Search:
        return { page: 1, pageSize: 50, isAscending: false, orderBy: 'number', type: ApplicationQueryType.All, statuses: [] };

      case ApplicationQueryType.ToReview:
        return { page: 1, pageSize: 50, isAscending: false, orderBy: 'number', type: type, statuses: [] };

      case ApplicationQueryType.Approvals:
      case ApplicationQueryType.Archive:
      case ApplicationQueryType.Unpaid:
      case ApplicationQueryType.Paid:
      case ApplicationQueryType.Reports:
        return { page: 1, pageSize: 50, isAscending: false, orderBy: 'number', type: type, statuses: [] };   

      default:
        throw new Error('Unknown ApplicationQueryType');
    }
  };
 
  const [value, setValue] = useSessionStorage<ApplicationQueryParameters>({
    key: getSessionKeyByLocation(),
    defaultValue: getDefaultApplicationQueryParametersByLocation(),
    getInitialValueInEffect: false,
  });

  return [value, setValue, getDefaultApplicationQueryParametersByLocation()];
};


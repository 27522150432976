import { FC } from 'react';
import { FormElementType } from './models/FormElementType';
import { FormElementComponentProps } from './FormElementComponentProps';
import { CheckboxGroup } from './FormElements/CheckboxGroup/CheckboxGroup';
import { Dropdown } from './FormElements/Dropdown/Dropdown';
import { FileBox } from './FormElements/FileBox/FileBox';
import { RadioButtonGroup } from './FormElements/RadioButtonGroup/RadioButtonGroup';
import { TextArea } from './FormElements/TextArea/TextArea';

import { AmountsTable } from './FormElements/AmountsTable/AmountsTable';
import { Repeater } from './FormElements/Repeater/Repeater';
import { TypedTextBox } from './FormElements/TypedTextBox/TypedTextBox';
import { SectionTitle } from './FormElements/SectionTitle/SectionTitle';
import { ContactPerson } from './FormElements/ContactPerson/ContactPerson';
import { DatePickerElement } from './FormElements/DatePickerElement/DatePickerElement';
import { ContactPersonOrganization } from './FormElements/ContactPersonOragnization/ContactPersonOrganization';
import { IdentityNumberPerson } from './FormElements/IdentityNumberPerson/IdentityNumberPerson';
import { IdentityNumberOrganization } from './FormElements/IdentityNumberOrganization/IdentityNumberOrganization';
import { WayOfPaymentElement } from './FormElements/WayOfPaymentElement/WayOfPaymentElement';
import { Paragraph } from './FormElements/Paragraph/Paragraph';

export const FormElementComponent: FC<FormElementComponentProps & any> = (props): JSX.Element => {
  const { element } = props;
  const { type } = element;

  const elementTypeMap: { [key in FormElementType]: JSX.Element } = {
    [FormElementType.TextArea]: <TextArea {...props} />,
    [FormElementType.AmountsTable]: <AmountsTable {...props} />,
    [FormElementType.Dropdown]: <Dropdown {...props} />,
    [FormElementType.RadioButtonGroup]: <RadioButtonGroup {...props} />,
    [FormElementType.CheckboxGroup]: <CheckboxGroup {...props} />,
    [FormElementType.FileGroup]: <FileBox {...props} />,
    [FormElementType.Repeater]: <Repeater {...props} />,
    [FormElementType.TypedTextBox]: <TypedTextBox {...props} />,
    [FormElementType.SectionTitle]: <SectionTitle {...props} />,
    [FormElementType.Paragraph]: <Paragraph {...props} />,
    [FormElementType.DatePickerElement]: <DatePickerElement {...props } />,
    [FormElementType.ContactPerson]: <ContactPerson {...props } />,
    [FormElementType.ContactPersonOrganization]: <ContactPersonOrganization {...props } />,
    [FormElementType.IdentityNumberPerson]: <IdentityNumberPerson {...props } />,
    [FormElementType.IdentityNumberOrganization]: <IdentityNumberOrganization {...props } />,
    [FormElementType.WayOfPayment]: <WayOfPaymentElement {...props } />,
  };

  return elementTypeMap[type] ?? new Error(`Unknown FormElementType: ${type}`);
};

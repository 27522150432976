import { Box, Stack, Input, TextInput, Group, Button, Select, Divider, Title, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { INPUT_MAX_LENGTH } from '../../App';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { FolderPermissionLevel } from '../../models/FolderPermissionLevel';
import { Role } from '../../models/Role';
import { CreateFolderRequest } from '../../models/CreateFolderRequest';
import { FolderContent } from '../../models/FolderContent';

type FormValues = {
  folderName: string;
  reviewerPermissionLevel: string;
  approverPermissionLevel: string;
};

interface CreateFolderFormProps {
  onSave: () => void;
  parentFolderId: string | undefined,
  folderContent: FolderContent | null,
}

export const EditFolderForm: React.FC<CreateFolderFormProps> = ({ onSave, parentFolderId, folderContent }) => {
  const form = useForm<FormValues>({
    initialValues: {
      folderName: folderContent?.name || '',
      reviewerPermissionLevel: folderContent?.permissions?.find(p => p.role === Role.Reviewer)?.permissionLevel.toString() || FolderPermissionLevel.None.toString(),
      approverPermissionLevel: folderContent?.permissions?.find(p => p.role === Role.Approver)?.permissionLevel.toString() || FolderPermissionLevel.None.toString(),
    },
    validate: {
      folderName: (value) => (value || value.length > 0 ? null : 'Namn på katalog måste anges'),
    },
  });

  const { selectedOrganisation } = useSession();
  const api = useApi();

  const create = async () => {
    if (isMissing(selectedOrganisation)) {
      return;
    }

    const request: CreateFolderRequest = {
      folderId: folderContent?.id || null,
      name: form.values.folderName,
      folderPermissions: [{
        role: Role.Reviewer, permissionLevel: Number.parseInt(form.values.reviewerPermissionLevel),
      }, {
        role: Role.Approver, permissionLevel: Number.parseInt(form.values.approverPermissionLevel),
      }],
      parentDocumentStorageFolderId: parentFolderId,
    };
    
    await api.upsertFolder(selectedOrganisation.id, request);

    onSave();
  };

  const permissionLevelDropdownData = [{
    label: t`Inga behörigheter`, value: FolderPermissionLevel.None.toString(),
  }, {
    label: t`Endast läsrättigheter`, value: FolderPermissionLevel.Read.toString(),
  }, {
    label: t`Rätt att läsa, ändra och radera dokument`, value: FolderPermissionLevel.Write.toString(),
  }];

  return (
    <Box w={'100%'}>
      <Stack gap='xl'>
        <form
          onSubmit={form.onSubmit(async () => {
            await create();
          })}>
          <Stack>
            <Input.Wrapper label={t`Namn`}>
              <TextInput {...form.getInputProps('folderName')} maxLength={INPUT_MAX_LENGTH} />
            </Input.Wrapper>
            <Divider orientation="vertical" />

            <Title order={4}>{t`Behörigheter`}</Title>
            <Text>{t`Välj vilken behörighetsnivå som ska gälla för olika användare.`}</Text>
            <Text>{t`Administratörer har alltid full behörighet`}</Text>

            <Select allowDeselect={false} label={t`Granskare`} data={permissionLevelDropdownData} {...form.getInputProps('reviewerPermissionLevel')}></Select>
            <Select allowDeselect={false} label={t`Beslutfattare`} data={permissionLevelDropdownData} {...form.getInputProps('approverPermissionLevel')}></Select>

            <Group justify={'end'} mt='md'>
              <Button type='submit'>{t`Spara`}</Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

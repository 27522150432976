import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { FolderContentType } from '../../models/FolderContentType';
import { FolderPermissionLevel } from '../../models/FolderPermissionLevel';
import { t } from 'ttag';
import { IconDownload, IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { Organisation } from '../../models/Organisation';
import { FolderPermission } from '../../models/FolderPermission';
import { FolderContent } from '../../models/FolderContent';
import { PagedResult } from '../../models/PagedResult';

export const createGridButtons = (
  selectedOrganisation: Organisation | undefined,
  currentUserPermission: FolderPermission | undefined,
  folderStructure: PagedResult<FolderContent> | undefined,
  downloadFile: (id: string) => Promise<void>,
  editFolder: (id: string) => Promise<void>,
  deleteItem: (id: string) => Promise<void>,
  openPreview: (id: string) => void,
): DisplayButtons[] => {
  const gridButtons: DisplayButtons[] = [
    {
      name: 'preview',
      icon: <IconEye size={24} />,
      onClick: (id: string) => openPreview(id),
      isDisabled: (row) =>
        row.type !== FolderContentType.Pdf && row.type !== FolderContentType.Images,
      tooltip: t`Visa`,
    },
    {
      name: 'download',
      icon: <IconDownload size={24} />,
      onClick: (id: string) => downloadFile(id),
      isDisabled(row) {
        return row.type === FolderContentType.Folder;
      },
      tooltip: t`Ladda ner`,
    },
  ];

  if (
    selectedOrganisation?.role === 2 ||
    currentUserPermission?.permissionLevel === FolderPermissionLevel.Write
  ) {
    gridButtons.push({
      tooltip: t`Redigera`,
      name: 'edit',
      icon: <IconEdit size={24} />,
      onClick: (id: string) => editFolder(id),
      isDisabled: (row) => {
        if (row.type !== FolderContentType.Folder) {
          return true;
        }

        if (selectedOrganisation?.role === 2) {
          return false;
        }

        if (row.type === FolderContentType.Folder) {
          const folder = folderStructure?.data.find((f: any) => f.id === row.id);
          const folderPermission = folder?.permissions?.find((p: any) => p.role === selectedOrganisation?.role);

          if (folderPermission && folderPermission?.permissionLevel < FolderPermissionLevel.Write) {
            return true;
          }

          return false;
        }

        return false;
      },
    });

    gridButtons.push({
      tooltip: t`Ta bort`,
      name: 'delete',
      icon: <IconTrash size={24} />,
      onClick: (id: string) => deleteItem(id),
      isDisabled: (row) => {
        if (selectedOrganisation?.role === 2) {
          return false;
        }

        if (row.type === FolderContentType.Folder) {
          const folder = folderStructure?.data.find((f: any) => f.id === row.id);
          const folderPermission = folder?.permissions?.find((p: any) => p.role === selectedOrganisation?.role);

          if (folderPermission && folderPermission?.permissionLevel < FolderPermissionLevel.Write) {
            return true;
          }

          return false;
        }

        return false;
      },
    });
  }

  return gridButtons;
};
import { ApplicationReviewStatus } from './ApplicationReviewStatus';
import { ApplicationStatus } from './ApplicationStatus';

export interface ApplicationItem {
  categoryColor: { color: string, inverted: string };
  id: string;
  status: ApplicationStatus;
  applicantName: string;
  applicantEmail: string;
  number: string;
  category: string;
  createdUtc: string;
  modifiedUtc: string;
  applicationPeriodId: string;
  applicationPeriodTitle: string;
  requestedAmount: number;
  suggestedAmount: number;
  reviewStatus: ApplicationReviewStatus;
  tags: ApplicationItem[];
}

export interface ApplicationItemTag {
  id: string;
  name: string;
  color: string;
}

export interface ApplicationQueryParameters {
  statuses: string[];
  tagIds?: string[] | null;
  applicationPeriodId?: string | null;
  keyword?: string | null;
  page: number;
  pageSize: number;
  orderBy?: string | null;
  isAscending: boolean;
  type: ApplicationQueryType;
}

export enum ApplicationQueryType {
  All = 0,
  ToReview = 1,
  Reviewed = 2,
  Approvals = 3,
  Archive = 4,
  Reports = 5,
  Search = 6,
  Unpaid = 7,
  Paid = 8,
}
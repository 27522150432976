import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Stack,
  TextInput,
  Title,
  Text,
  Paper,
  NavLink,
  useMantineTheme,
  Container,
  Grid,
  ActionIcon,
  Space,
  Menu,
  FileButton,
  LoadingOverlay,
} from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { IconAlertTriangleFilled, IconArrowLeft, IconChevronDown, IconDownload, IconGripVertical, IconPlayerPlayFilled, IconPlayerStopFilled, IconPlus, IconSettings, IconTrash, IconUpload } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { FormPageBuilder } from './FormPageBuilder';
import { FormPage } from './models/FormPage';
import { FormModel } from './models/FormModel';
import { countAndValidateElements } from './CountElements';
import { Counters } from './ElementCount';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { reorderArray } from '../../utils/reorderArray';
import { LiitActionIcon } from '../LiitActionIcon/LiitActionIcon';
import { ApplicantType } from '../../models/ApplicantType';
import { TranslationItem } from '../../models/TranslationItem';
import { LanguageCode } from '../../models/LanguageCode';
import LanguageHandler from '../../utils/languageHandler';
import { FormElementType } from './models/FormElementType';
import { ToolboxSetting, ToolBoxSetup } from './FormBuilderToolbox/ToolboxItemSettings';
import { TextBoxInputType } from './FormElements/TypedTextBox/TextBoxInputType';
import { LiitDrawer } from '../LiitDrawer/LiitDrawer';
import { INPUT_MAX_LENGTH } from '../../App';
import { PaymentOptions } from '../../models/PaymentOptions';
import { FormRunner } from './FormRunner';
import { FormElementMode } from './FormElementMode';
import { formBuilderStyles } from './FormBuilderStyles';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useListState } from '@mantine/hooks';
import { showConfirmCancelModal, showConfirmDeleteModal, showFailNotification } from '../../utils/notificationHelper';
import { HoverTooltip } from '../HoverTooltip';
import { download } from '../../utils/download';

interface FormBuilderProps {
  applicantType: ApplicantType;
  paymentOption: PaymentOptions;
  json: string;
  title: string;
  onChanged: (json: string) => void;
  onSave: () => void;
  onCancel: () => void;
  setFormStatus: React.Dispatch<React.SetStateAction<string[]>>;
}

interface FormValues {
  id: string;
  label: TranslationItem[];
}

const getToolBox = (applicantType: ApplicantType, paymentOption: PaymentOptions): ToolBoxSetup => {
  let inputTypes: TextBoxInputType[] = [
    TextBoxInputType.ContactPersonEmail,
    TextBoxInputType.ContactPersonPhoneNumber,
    TextBoxInputType.Plain,
    TextBoxInputType.Email,
    TextBoxInputType.PhoneNumber,
    TextBoxInputType.Title,
    TextBoxInputType.Number,
  ];

  if (paymentOption !== PaymentOptions.NoPayment) {
    inputTypes.push(TextBoxInputType.RequestedAmount);
  }

  const elementTypes: FormElementType[] = [
    FormElementType.TypedTextBox,
    FormElementType.TextArea,
    FormElementType.Dropdown,
    FormElementType.FileGroup,
    FormElementType.CheckboxGroup,
    FormElementType.RadioButtonGroup,
    FormElementType.DatePickerElement,
    FormElementType.AmountsTable,
    FormElementType.SectionTitle,
    FormElementType.Paragraph,
    FormElementType.Repeater,
  ];

  if (paymentOption === PaymentOptions.Default) {
    elementTypes.push(FormElementType.WayOfPayment);
  }

  if (applicantType === ApplicantType.Person) {
    elementTypes.push(FormElementType.ContactPerson);
    elementTypes.push(FormElementType.IdentityNumberPerson);

    const extraInput: TextBoxInputType[] = [
      TextBoxInputType.ApplicantFirstName,
      TextBoxInputType.ApplicantLastName,
    ];

    inputTypes = extraInput.concat(inputTypes);
  }

  if (applicantType === ApplicantType.Organization) {
    elementTypes.push(FormElementType.ContactPersonOrganization);
    elementTypes.push(FormElementType.IdentityNumberOrganization);

    const extraInput: TextBoxInputType[] = [TextBoxInputType.ApplicantOrganizationName];

    inputTypes = extraInput.concat(inputTypes);
  }

  return { elementTypes: elementTypes, inputTypes: inputTypes };
};

export const FormBuilder: React.FC<FormBuilderProps> = ({
  json,
  onChanged,
  onSave,
  onCancel,
  applicantType,
  paymentOption,
  setFormStatus,
  title,
}) => {
  const [editForm, setEditForm] = useState<FormModel | null>(null);
  const [propertyEditorOpened, setPropertyEditorOpened] = useState(false);
  const [counters, setCounters] = useState<Counters>({
    elements: [],
    inputTypes: [],
    missingElements: [],
  });
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [toolBox, setToolBox] = useState<ToolBoxSetup>();
  const theme = useMantineTheme();
  const { classes } = formBuilderStyles();
  const [state, handlers] = useListState<FormPage>([]);
  const [preview, setPreview] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      id: '',
      label: [{ language: LanguageCode.sv, text: '' }],
    },
  });

  useEffect(() => {
    setToolBox(getToolBox(applicantType, paymentOption));
  }, []);

  useEffect(() => {
    if (json && toolBox) {
      const result: FormModel = JSON.parse(json);
      const countAndValidation = countAndValidateElements(result.pages, toolBox);
      setCounters(countAndValidateElements(result.pages, toolBox));

      const missingElements = countAndValidation.missingElements;
      setFormStatus(missingElements);
      setErrors(missingElements);

      if (currentPageIndex > result.pages.length - 1) {
        setCurrentPageIndex(currentPageIndex - 1);
      }

      setEditForm(result);
      handlers.setState(result.pages);


    }
  }, [json, toolBox]);

  if (isMissing(editForm)) {
    return <Loader />;
  }

  const onPageUpdated = () => {
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const addPage = () => {
    editForm.pages.push({
      id: uuidv4(),
      label: [{ language: LanguageCode.sv, text: 'Ny sida' }],
      elements: [],
    } as FormPage);
    onChanged(JSON.stringify(editForm, null, 4));
    setCurrentPageIndex(editForm.pages.length - 1);
  };

  const removePage = (page: FormPage) => {
    showConfirmDeleteModal(t`Vill du verkligen ta bort sidan?`, () => {
      editForm.pages = editForm.pages.filter((p) => p.id !== page.id);
      onChanged(JSON.stringify(editForm, null, 4));
    });
  };

  const editPage = (page: FormPage) => {
    form.setValues(page);
    setPropertyEditorOpened(true);
  };

  const updatePage = () => {
    const index = editForm.pages.findIndex((x) => x.id === form.values.id);
    editForm.pages[index].label = form.values.label; //KOLLA OM DETTA FUNKAR
    setPropertyEditorOpened(false);
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const getErrors = () => {

    return <Stack p={'md'} gap={0}>
      <Text size={'sm'}>För att kunna spara formuläret måste du lägga till följande obligatoriska elment:</Text>
      <Space h={'sm'} />
      {errors.map(x => <Text size={'sm'} key={x}>- {x}</Text>)}
    </Stack>;
  };

  const hasErrors = errors.length > 0;

  const getPageTitle = (page: FormPage) => {
    return LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv);
  };

  const cancel = () => {
    showConfirmCancelModal(t`Vill du avbryta? Alla eventuella ändringar som inte är sparade kommer att försvinna.`, onCancel);
  };

  const renderPage = (pageIndex: number, questionNumber: number): JSX.Element => {
    const page = editForm.pages[pageIndex];
    const label = getPageTitle(page);

    const elements = (
      <Paper m={'xl'} p={'lg'} withBorder>
        <Stack key={`page_${page.id}}`}>
          <Group>
            <Title order={4}>{label}</Title>
          </Group>
          <FormPageBuilder
            page={page}
            questionNumber={questionNumber}
            onPageUpdated={onPageUpdated}
            toolbox={{ counters, ...toolBox } as ToolboxSetting}
          />
        </Stack>
      </Paper>
    );

    questionNumber += page.elements.length;

    return elements;
  };

  const renderCurrentPage = (): JSX.Element => {

    if (currentPageIndex > -1 && currentPageIndex < editForm.pages.length) {
      // TODO: wth is questionnumber? do we really need it?
      const questionNumber = 1;
      return renderPage(currentPageIndex, questionNumber);
    }

    return <></>;
  };

  const items = state.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <NavLink key={`toc_${item.id}`}
            onClick={() => setCurrentPageIndex(index)}
            active={item.id === editForm.pages[currentPageIndex]?.id}
            label={getPageTitle(item)}
            leftSection={
              <IconGripVertical size="1rem" stroke={1.5} />
            }
            rightSection={
              <>
                <LiitActionIcon
                  tooltip={t`Inställningar`}
                  icon={<IconSettings size={16} />}
                  variant="subtle"
                  onClick={() => editPage(item)}
                />
                <LiitActionIcon
                  tooltip={t`Ta bort`}
                  icon={<IconTrash size={16} />}
                  variant="subtle"
                  onClick={() => removePage(item)}
                />
              </>
            } />
        </div>
      )}
    </Draggable>
  ));

  const getTableOfContents = () => {

    return <Stack gap={0}>
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          handlers.reorder({ from: source.index, to: destination?.index || 0 });

          if (destination) {
            reorderArray(editForm.pages, source.index, destination.index);
            onChanged(JSON.stringify(editForm, null, 4));
          }
        }
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>;

  };

  const downloadForm = async () => {
    const jsonData = JSON.stringify(editForm, null, 4);
    const filename = `${title}-form.json`.trim();
    await download(jsonData, filename);
  };

  return (


    <Flex direction={'column'} w={'100%'}>
      <LoadingOverlay visible={loadingOverlayVisible} overlayProps={{ blur: 2 }} />
      <div style={{ height: '60px', borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
        <Grid>
          <Grid.Col span={4}>
            <Group h={60} wrap='nowrap' pl={'sm'}>
              <ActionIcon
                variant={'subtle'}
                pt={2}
                color="blue.7"
                size="md"
                radius="xl"
                onClick={() => cancel()}>
                <IconArrowLeft size={30} />
              </ActionIcon>

              <Text fw={'bold'} fz={'h4'} truncate>{title}</Text>
            </Group>

          </Grid.Col>
          <Grid.Col span={4}>
            <Group p={'sm'} justify='center'>
              {preview ? (
                <Button variant="light" leftSection={<IconPlayerStopFilled />} onClick={() => setPreview(false)}>Avbryt</Button>
              ) : (
                <Button variant="light" leftSection={<IconPlayerPlayFilled />} onClick={() => setPreview(true)}>Förhandsgranska</Button>
              )}
            </Group>
          </Grid.Col>
          <Grid.Col span={4}>

            <Group p={'sm'} wrap='nowrap' justify='flex-end'>

              <Menu shadow="md" onClose={() => setMenuOpened(false)} opened={menuOpened} closeOnClickOutside={true}>
                <Menu.Target>
                  <Button variant={'light'} onClick={() => setMenuOpened(!menuOpened)} rightSection={<IconChevronDown size={18} stroke={1.5} />}>Alternativ</Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={async () => downloadForm()} leftSection={<IconDownload size={14} />}>
                    {t`Ladda ner formulärdata`}
                  </Menu.Item>
                  <FileButton
                    accept="application/json"
                    onChange={(payload) => {
                      if (payload) {
                        setLoadingOverlayVisible(true);
                        const reader = new FileReader();
                        reader.readAsText(payload);

                        reader.onload = async () => {
                          try {

                            const jsonData = reader.result as string;
                            setCurrentPageIndex(0);
                            onChanged(jsonData);
                            setMenuOpened(false);
                          } catch (exception) {
                            if (exception instanceof Error) {
                              showFailNotification(undefined, exception.toString());
                            } else {
                              showFailNotification(undefined, String(exception));
                            }
                          } finally {
                            setLoadingOverlayVisible(false);
                          }
                        };
                      }
                    }}>
                    {(props) => (
                      <Menu.Item{...props} onClick={() => {
                        setLoadingOverlayVisible(true);
                        // eslint-disable-next-line react/prop-types
                        props.onClick();
                      }} leftSection={<IconUpload size={14} />} closeMenuOnClick={false} component="div">
                        {t`Ladda upp formulärdata`}
                      </Menu.Item>
                    )}
                  </FileButton>
                </Menu.Dropdown>
              </Menu>

              <Divider orientation='vertical' />

              <Button variant="light" onClick={() => cancel()}>Avbryt</Button>

              {!hasErrors && (
                <Button onClick={() => onSave()}>Spara</Button>
              )}

              {hasErrors && (
                <HoverTooltip content={getErrors()}>
                  <Button disabled={hasErrors} leftSection={
                    <IconAlertTriangleFilled color={theme.colors.red[4]} />
                  }>Spara</Button>
                </HoverTooltip>
              )}

            </Group>
          </Grid.Col>
        </Grid>
      </div>

      <div style={{ display: 'flex', flex: 1, overflow: 'auto', background: theme.colors.gray[1] }}>

        {preview ? (
          <Container flex={'1 1 auto'} size={'lg'} p={'xl'} >
            <FormRunner
              runnerMode={FormElementMode.RunnerPreview}
              startPageIndex={currentPageIndex}
              json={json}
              moveUpNavigation={92}
            />
          </Container>
        ) : (
          <>
            <div style={{ flex: 1, overflow: 'none', background: theme.colors.gray[1], borderRight: `1px solid ${theme.colors.gray[3]}` }}>
              <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1, background: theme.colors.gray[1] }}>
                <div style={{ height: '48px', background: theme.colors.gray[1], borderBottom: `1px solid ${theme.colors.gray[3]}` }} >
                  <Group p={'sm'} justify="space-between">
                    <b>Sidor</b>
                    <LiitActionIcon
                      tooltip={t`Lägg till sida`}
                      icon={<IconPlus size={16} />}
                      variant="subtle"
                      onClick={() => addPage()} />
                  </Group>

                </div>
                <div style={{ overflow: 'auto', background: theme.colors.gray[1] }} >
                  {getTableOfContents()}
                </div>
              </div>
            </div>

            <div style={{ flex: 4, overflow: 'auto', background: theme.colors.gray[0] }} >
              {renderCurrentPage()}
            </div>
          </>
        )}
      </div>

      <LiitDrawer
        opened={propertyEditorOpened}
        onClose={() => setPropertyEditorOpened(false)}
        size={'xl'}>
        <Flex direction={'column'} w={'100%'}>
          <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
            <TextInput label={t`Sidnamn`} {...form.getInputProps('label.0.text')} maxLength={INPUT_MAX_LENGTH} />
          </Box>
          <Group justify={'end'} pt={'xl'}>
            <Button variant={'light'} onClick={() => setPropertyEditorOpened(false)}>{t`Avbryt`}</Button>
            <Button onClick={() => updatePage()}>{t`Spara`}</Button>
          </Group>
        </Flex>
      </LiitDrawer>

    </Flex >
  );
};

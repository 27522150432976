import { ValidationResult } from '../../../../models/ValidationResult';
import { WayOfPayment } from '../../../../models/WayOfPayment';
import { ErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';
import { WayOfPaymentElementValue } from './WayOfPaymentValue';
import Validator from '../../../../utils/validators';

export const validateWayOfPaymentElement = (element: FormElement): ValidationResult => { 
  const typedValue = element.value as WayOfPaymentElementValue;

  if (typedValue.wayOfPayment === WayOfPayment.Undefined) {
    return { isValid: false, errorMessage: ErrorMessages.SELECT_WAY_OF_PAYMENT };
  }

  if (typedValue.wayOfPayment === WayOfPayment.BankAccount && (!typedValue.clearingNumber || !typedValue.accountNumber )) {
    return { isValid: false, errorMessage: ErrorMessages.FAULTY_CLEARING_AND_ACCOUNTNUMBER };
  }

  if (typedValue.wayOfPayment === WayOfPayment.BankGiro) { 
    if (!typedValue.bgNumber) {
      return { isValid: false, errorMessage: ErrorMessages.BG_NUMBER_MISSING };
    }

    if (!Validator.validateBGNumber(typedValue.bgNumber)) {
      return { isValid: false, errorMessage: ErrorMessages.BG_NUMBER_FAULTY };
    }
  }

  if (typedValue.wayOfPayment === WayOfPayment.PlusGiro) {
    if (!typedValue.pgNumber) {
      return { isValid: false, errorMessage: ErrorMessages.PG_NUMBER_MISSING };
    }

    if (!Validator.validatePGNumber(typedValue.pgNumber)) {
      return { isValid: false, errorMessage: ErrorMessages.PG_NUMBER_FAULTY };
    }
  }

  if (typedValue.wayOfPayment === WayOfPayment.Other && !typedValue.otherValue) {
    return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
  }

  return { isValid: true, errorMessage: '' };
};
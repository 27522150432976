import { t } from 'ttag';

export enum ApplicantType {
  Person = 0,
  Organization = 1,
}

export const ApplicantTypeItems: Record<ApplicantType, string> = { 
  [ApplicantType.Person]: t`Privatpersoner`,
  [ApplicantType.Organization]: t`Organisationer`,
};
import { Container, Space, MantineSize, BoxProps } from '@mantine/core';
import React from 'react';
import { isPresent } from 'utilitype';

interface GrantMeContainerProps extends BoxProps {
  padding?: number;
  size?: MantineSize;
  children: React.ReactNode;
  takeAllSpace?: boolean;
  scrollable?: boolean;
}

export const GrantMeContainer: React.FC<GrantMeContainerProps> = ({
  padding,
  size,
  children,
  className,
  takeAllSpace,
  scrollable = true,
}) => {
  const cs = isPresent(size) ? size : 'xl';
  const p = isPresent(padding) ? padding : takeAllSpace ? 0 : 32;

  return (
    <>
      {p != 0 && <Space h={p} />}
      <Container
        ml={0}
        pl={0}
        pr={0}
        maw={'unset'}
        size={cs}
        className={className}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: scrollable ? 'auto' : 'hidden',
        }}>
        {children}
      </Container>
    </>
  );
};

import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { RenderDocumentsNameCell } from './RenderDocumentsCell';
import { RenderDocumentUserCell } from './RenderDocumentUserCell';
import { RenderDocumentFileSizeCell } from './RenderDocumentFileSizeCell';
import { RenderDocumentPermissionsCell } from './RenderDocumentPermissionsCell';

export const createDocumentListColumns = (selectedOrganisation: any): Array<IDisplayColumn> => {
  const columns: Array<IDisplayColumn> = [
    {
      propertyName: 'name',
      propertyType: PropertyType.Text,
      displayName: 'Namn',
      disableSort: true,
      renderCell: RenderDocumentsNameCell,
      columnStyles: {
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        userSelect: 'none',
        paddingLeft: 40,
      },
    },
    {
      propertyName: 'lastModifiedBy',
      propertyType: PropertyType.Text,
      displayName: 'Senast ändrad av',
      renderCell: RenderDocumentUserCell,
      disableSort: true,
      hideAt: 'md',
    },
    {
      propertyName: 'lastModifiedDate',
      propertyType: PropertyType.DateTimeUtcToLocal,
      displayName: 'Senast ändrad',
      disableSort: true,
      hideAt: 'md',
    },
    {
      propertyName: 'size',
      propertyType: PropertyType.Number,
      displayName: 'Filstorlek',
      renderCell: RenderDocumentFileSizeCell,
      disableSort: true,
    },
  ];

  if (selectedOrganisation?.role === 2) {
    columns.push({
      propertyName: 'permissions',
      propertyType: PropertyType.Text,
      displayName: 'Behörigheter',
      renderCell: RenderDocumentPermissionsCell,
      disableSort: true,
      hideAt: 'md',
    });
  }

  return columns;
};
import { Text, Flex, Group } from '@mantine/core';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { t } from 'ttag';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { HoverTooltip } from '../../../components/HoverTooltip';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ReactNode } from 'react';
import Formatter from '../../../utils/formatter';

export const RenderReviewersCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { rowValues, theme } = rowInformation;
  const { id, reviewMaxCount, reviewCompletedCount, reviewBiasedCount, reviewDetails } = rowValues;
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const reviewCompletionStatus = reviewDetails?.reviewCompletionStatus;

  const getReviewDetails = async () => {

    if (reviewDetails)
      return;

    const response = await api.getReviewDetails(selectedOrganisation!.id, id);

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.reviewDetails = response; 
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  const getPopoverContent = () => {
    return <Flex p={12} direction={'column'}>
    {reviewCompletionStatus?.map((info) => (
      <Group gap={12} key={info.name} justify={'space-between'}>
        <Text size={'xs'} fw={500}>
          {info.name}
        </Text>
        <Text size={'xs'}>
          {Formatter.formatCurrency(info.suggestedAmount)}
        </Text>        
        {info.isBiased ? (
          <Text size={'xs'} color={theme.colors.red[7]} fw={600}>
            {t`Jäv`}
          </Text>
        ) : info.reviewDone ? (
          <IconCheck size={14} color={theme.colors.green[5]} />
        ) : (
          <IconX size={14} color={theme.colors.red[3]} />
        )}
      </Group>
    ))}
  </Flex>;
  };

  return (
      <HoverTooltip content={getPopoverContent()} onOpen={() => getReviewDetails()}>
        <Group justify="center" gap={'sm'}>
          {`${reviewCompletedCount}/${reviewMaxCount}`}
          {reviewBiasedCount > 0 && 
            <IconAlertTriangle color={theme.colors.orange[5]}></IconAlertTriangle>
          }         
        </Group>
      </HoverTooltip>
  );
};

import {
  Button,
  Checkbox,
  Stack,
  Textarea,
  TextInput,
  Group,
  ActionIcon,
  SimpleGrid,
  NumberInput,
  Select,
  Flex,
  Box,
  Input,
  Text,
  Combobox,
  useCombobox,
  InputBase,
  useMantineTheme,
  ScrollArea,
} from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { FC, useEffect } from 'react';
import { useForm } from '@mantine/form';
import { v4 as uuidv4 } from 'uuid';
import { FormElement } from './models/FormElement';
import { FormElementType } from './models/FormElementType';
import { t } from 'ttag';
import { LanguageCode } from '../../models/LanguageCode';
import LanguageHandler from '../../utils/languageHandler';
import { InputTypesSetting } from './FormBuilderToolbox/ToolboxItemSettings';
import { ToolboxItems } from './FormBuilderToolbox/ToolBoxItems';
import { TranslationItem } from '../../models/TranslationItem';
import { INPUT_MAX_LENGTH } from '../../App';
import { isEmpty } from 'utilitype';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import { FormElementWayOfPayment } from './FormElements/WayOfPaymentElement/WayOfPaymentElement';
import { wayOfPaymentLabels } from '../../utils/wayOfPaymentLabels';

interface FormBuilderPropertyViewProps {
  selectedElement: FormElement & any;
  apply: (element: FormElement) => void;
  close: () => void;
  availableInputTypes?: InputTypesSetting[];
}

export const FormBuilderPropertyView: FC<FormBuilderPropertyViewProps> = ({
  selectedElement,
  apply,
  close,
  availableInputTypes,
}) => {
  const form = useForm({
    initialValues: {
      ...selectedElement,
      sizeSelectValue: selectedElement.size?.toString() ?? null,
      inputTypeSelectValue: selectedElement.inputType?.toString() ?? null,
    },
    validate: {
      addButtonText: (value) =>
        selectedElement.type !== FormElementType.Repeater ||
          LanguageHandler.getTextByLanguage(value, LanguageCode.sv).trim()
          ? null
          : t`Knappen måste ha ett värde`,

      options: {
        label: (value) =>
          LanguageHandler.getTextByLanguage(value, LanguageCode.sv).trim()
            ? null
            : t`Ange ett värde`,
      },
    },
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const theme = useMantineTheme();

  const renderWayOfPaymentOptions = (element: FormElementWayOfPayment) => {
    const checkedWayOfPayments = element.allowedPaymentMethods ?? [];

    return (
      <Input.Wrapper label={t`Utbetalningssätt`} description={t`Möjliga utbetalningssätt`}>
        <Stack pr={8} pt={16}>
          {wayOfPaymentLabels.map((item, index) => {
            return (
              <Checkbox
                key={`${element.id}_option_${item.label}`}
                label={item.label}
                pl={4}
                checked={checkedWayOfPayments.some((wopm) => wopm === item.value)}
                onChange={(event) => {
                  const isChecked = event.currentTarget.checked;

                  if (isChecked) {
                    form.insertListItem('allowedPaymentMethods', item.value);
                  } else {
                    const findIndex = checkedWayOfPayments.indexOf(item.value);
                    form.removeListItem('allowedPaymentMethods', findIndex);
                  }
                }}
              />
            );
          })}
        </Stack>
      </Input.Wrapper>
    );
  };

  const renderOptions = (element: FormElement) => {
    const canHaveMandatoryOptions =
      element.type === FormElementType.Dropdown ||
      element.type === FormElementType.RadioButtonGroup;

    return (
      <>
        <Input.Wrapper
          label={t`Värden`}
          description={
            canHaveMandatoryOptions
              ? t`Checkade värden fungerar som kontrollfrågor. Om inga är valda görs ingen kontroll`
              : undefined
          }>
          <Stack pr={8} pt={canHaveMandatoryOptions ? 8 : 0}>
            {element.options.map((option, index) => (
              <Group
                key={`${element.id}_option_${option.id}`}
                justify={'space-between'}
                align={'center'}>
                {canHaveMandatoryOptions && (
                  <Checkbox
                    pl={4}
                    checked={option.isMandatory ?? false}
                    onChange={(event) => {
                      const isChecked = event.currentTarget.checked;

                      if (
                        !isChecked &&
                        !element.options.filter((x) => x !== option).some((x) => x.isMandatory)
                      ) {
                        form.setFieldValue('controlQuestionErrorText', null);
                      }

                      form.setFieldValue(
                        `options.${index}.isMandatory`,
                        event.currentTarget.checked,
                      );
                    }}
                  />
                )}
                <TextInput
                  {...form.getInputProps(`options.${index}.label.0.text`)}
                  style={{ flexGrow: 1 }}
                  maxLength={INPUT_MAX_LENGTH}
                />
                <ActionIcon
                  variant="subtle"
                  onClick={() =>
                    form.insertListItem(
                      'options',
                      {
                        id: uuidv4(),
                        label: [{ language: LanguageCode.sv, text: '* FYLL I VÄRDE *' }],
                      },
                      index + 1,
                    )
                  }>
                  <IconPlus size={16} />
                </ActionIcon>
                <ActionIcon variant="subtle" onClick={() => form.removeListItem('options', index)}>
                  <IconMinus size={16} />
                </ActionIcon>
              </Group>
            ))}
          </Stack>
        </Input.Wrapper>
        {canHaveMandatoryOptions && element.options.some((x) => x.isMandatory) && (
          <TextInput
            label={t`Kontrollfråga feltext`}
            description={t`Denna text visas om användaren inte väljer ett svar som är checkat`}
            value={
              form.values.controlQuestionErrorText
                ? LanguageHandler.getTextByLanguage(
                  form.values.controlQuestionErrorText,
                  LanguageCode.sv,
                )
                : ''
            }
            maxLength={INPUT_MAX_LENGTH}
            onChange={(event) => {
              const eventValue = event.currentTarget.value;
              if (eventValue) {
                const newValue: TranslationItem = { language: LanguageCode.sv, text: eventValue };
                form.setFieldValue('controlQuestionErrorText', [newValue]);
              } else {
                form.setFieldValue('controlQuestionErrorText', null);
              }
            }}
          />
        )}
      </>
    );
  };

  const getOptions = (items: InputTypesSetting[] | undefined) =>
    !items || isEmpty(items)
      ? []
      : items?.map((inputType) => {
        return (
          <Combobox.Option
            disabled={inputType.limitReached}
            value={inputType.type.toString()}
            key={inputType.label}>
            <Group wrap={'nowrap'} justify={'space-between'}>
              <Text size="xs" fw={500}>
                {inputType.label}
              </Text>
              {inputType.count !== undefined && (
                <Text size="xs" opacity={0.85} pr={8}>
                  {`${inputType.count ?? 0} / ${inputType.limit ?? '*'}`}
                </Text>
              )}
            </Group>
          </Combobox.Option>
        );
      });

  const getGroupedOptions = () => {
    const mandatory = getOptions(availableInputTypes?.filter((item) => item.mandatory));
    const other = getOptions(availableInputTypes?.filter((item) => !item.mandatory));

    return (
      <Combobox.Options>
        <ScrollArea.Autosize type="scroll" mah={220}>
          <Combobox.Group label={t`Obligatorisk`}>{mandatory}</Combobox.Group>
          <Combobox.Group label={t`Övriga`}>{other}</Combobox.Group>
        </ScrollArea.Autosize>
      </Combobox.Options>
    );
  };

  const getInputTypeSelectedLabel = (): InputTypesSetting | undefined => {
    const selectedValue = form.values.inputTypeSelectValue;
    const item = availableInputTypes?.find((x) => x.type.toString() === selectedValue);

    return item;
  };

  const renderInputType = () => (
    <Combobox
      styles={{
        groupLabel: {
          color: theme.colors[theme.primaryColor][7],
        },
      }}
      store={combobox}
      onOptionSubmit={(val) => {
        form.setFieldValue('inputTypeSelectValue', val);
        combobox.closeDropdown();
      }}
      {...form.getInputProps('inputTypeSelectValue')}>
      <Combobox.Target>
        <InputBase
          label={'Typ'}
          component={'button'}
          type={'button'}
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}>
          {getInputTypeSelectedLabel()?.label ?? <Input.Placeholder>Pick value</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>{getGroupedOptions()}</Combobox.Dropdown>
    </Combobox>
  );

  useEffect(() => {
    if (!form.values.minRows || !form.values.maxRows) {
      return;
    }

    if (form.values.minRows > form.values.maxRows) {
      form.setFieldValue('maxRows', form.values.minRows);
    }
  }, [form.values.minRows]);

  const submit = () => {
    const result = form.validate();

    if (!result.hasErrors) {
      const parsedResult = {
        ...form.values,
        size: Number(form.values.sizeSelectValue),
        inputType: Number(form.values.inputTypeSelectValue),
      };
      apply(parsedResult);
    }
  };

  if (selectedElement.type === FormElementType.SectionTitle) {
    return (
      <Flex direction={'column'} w={'100%'}>
        <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Stack gap={'lg'} p={0}>
            <TextInput
              label={t`Titel`}
              {...form.getInputProps('label.0.text')}
              maxLength={INPUT_MAX_LENGTH}
            />
          </Stack>
        </Box>
        <Group justify={'end'} pt={'xl'}>
          <Button variant="outline" onClick={() => close()}>{t`Avbryt`}</Button>
          <Button onClick={() => submit()}>{t`Tillämpa`}</Button>
        </Group>
      </Flex>
    );
  }

  if (selectedElement.type === FormElementType.Paragraph) {

    const richTextEditor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Link,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: form.values.value,
      onUpdate: ({ editor }) => {
        form.values.value = editor.getHTML();
      },      
    });

    return (
      <Flex direction={'column'} w={'100%'}>
        <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
          <Stack gap={'lg'} p={0}>
            <Input.Wrapper
              label={'Text'}>
              <RichTextEditor editor={richTextEditor} fz={'sm'}>
                <RichTextEditor.Toolbar>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.ClearFormatting />
                  </RichTextEditor.ControlsGroup>

                 <RichTextEditor.ControlsGroup>
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link initialExternal={true} />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
              </RichTextEditor>
            </Input.Wrapper>
          </Stack>
        </Box>
        <Group justify={'end'} pt={'xl'}>
          <Button variant="outline" onClick={() => close()}>{t`Avbryt`}</Button>
          <Button onClick={() => submit()}>{t`Spara`}</Button>
        </Group>
      </Flex>
    );
  }

  if (selectedElement.type === FormElementType.WayOfPayment) {
    return (
      <Flex direction={'column'} w={'100%'}>
        <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
          <form>
            <Stack gap={'lg'} p={0}>
              <Textarea label={t`Beskrivning`} {...form.getInputProps('description.0.text')} />
              {renderWayOfPaymentOptions(form.values)}
            </Stack>
          </form>
        </Box>
        <Group justify={'end'} pt={'xl'}>
          <Button variant="outline" onClick={() => close()}>{t`Avbryt`}</Button>
          <Button disabled={form.values.allowedPaymentMethods.length < 1} onClick={() => submit()}>{t`Spara`}</Button>
        </Group>
      </Flex>
    );
  }

  return (
    <Flex direction={'column'} w={'100%'}>
      <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Stack gap={'lg'} p={0}>
          <TextInput
            label={t`Titel`}
            {...form.getInputProps('label.0.text')}
            maxLength={INPUT_MAX_LENGTH}
          />
          <Textarea label={t`Beskrivning`} {...form.getInputProps('description.0.text')} />
          {selectedElement.type === FormElementType.TypedTextBox && renderInputType()}
          {form.values.type === FormElementType.Repeater && (
            <>
              <TextInput
                label={t`Knapptext`}
                {...form.getInputProps('addButtonText.0.text')}
                maxLength={INPUT_MAX_LENGTH}
              />
              <NumberInput
                label={t`Max antal`}
                min={0}
                max={100}
                {...form.getInputProps('maxItems')}
              />
              <Checkbox
                pl={4}
                style={{ display: 'flex' }}
                label={t`Text endast på först raden`}
                {...form.getInputProps('labelsOnFirstGroup', { type: 'checkbox' })}
              />
              <Checkbox
                pl={4}
                style={{ display: 'flex' }}
                label={t`Summera nummerfält`}
                {...form.getInputProps('sumNumberFields', { type: 'checkbox' })}
              />
            </>
          )}
          {ToolboxItems[selectedElement.type].canResize && (
            <Select
              label={t`Storlek`}
              data={[
                { value: '12', label: '100%' },
                { value: '9', label: '75%' },
                { value: '8', label: '66%' },
                { value: '6', label: '50%' },
                { value: '4', label: '33%' },
                { value: '3', label: '25%' },
                { value: '2', label: '17%' },
              ]}
              {...form.getInputProps('sizeSelectValue')}
            />
          )}
          <Checkbox
            pl={4}
            style={{ display: 'flex' }}
            label={t`Obligatorisk`}
            {...form.getInputProps('required', { type: 'checkbox' })}
          />
          <Select
            label={t`Titel och textposition`}
            data={[
              { value: 'left', label: 'Vänster' },
              { value: 'right', label: 'Höger' },
            ]}
            {...form.getInputProps('labelAndTextPosition')}
            value={form.values.labelAndTextPosition ?? 'left'}
          />
          {form.values.type === FormElementType.TextArea && (
            <SimpleGrid cols={2}>
              <div>
                <NumberInput
                  min={1}
                  max={10}
                  defaultValue={1}
                  label="Min Rader"
                  {...form.getInputProps('minRows')}
                />
              </div>
              <div>
                <NumberInput
                  min={1}
                  max={10}
                  defaultValue={1}
                  label="Max rader"
                  {...form.getInputProps('maxRows')}
                />
              </div>
            </SimpleGrid>
          )}

          {form.values.type === FormElementType.TextArea && (
            <NumberInput
              label={t`Max antal tecken`}
              description={t`Visar räknare för hur många tecken man får ha och hur många som utnyttjats (Max 10000 tecken)`}
              max={10000}
              min={0}
              hideControls
              {...form.getInputProps('maxCharCount')}
            />
          )}

          {(form.values.type === FormElementType.Dropdown ||
            form.values.type === FormElementType.CheckboxGroup ||
            form.values.type === FormElementType.RadioButtonGroup ||
            form.values.type === FormElementType.AmountsTable) &&
            renderOptions(form.values)}

          {(form.values.type === FormElementType.Dropdown ||
            form.values.type === FormElementType.CheckboxGroup ||
            form.values.type === FormElementType.RadioButtonGroup) && (
              <Checkbox
                pl={4}
                checked={form.values.enableAutoTagging ?? false}
                label={t`Automatisk kategorisering`}
                description={t`Kategorier kommer automatiskt att skapas upp för de värden som användaren väljer.`}
                onChange={(event) => {
                  form.setFieldValue('enableAutoTagging', event.currentTarget.checked);
                }}
              />
          )}

          <Textarea
            label={t`Hjälptext`}
            autosize={true}
            minRows={2}
            maxRows={6}
            maxLength={1500}
            value={
              form.values.helpText
                ? LanguageHandler.getTextByLanguage(form.values.helpText, LanguageCode.sv)
                : ''
            }
            onChange={(event) => {
              const eventValue = event.currentTarget.value;
              if (eventValue) {
                const newValue: TranslationItem = { language: LanguageCode.sv, text: eventValue };
                form.setFieldValue('helpText', [newValue]);
              } else {
                form.setFieldValue('helpText', null);
              }
            }}
          />
        </Stack>
      </Box>
      <Group justify={'end'} pt={'xl'}>
        <Button variant={'outline'} onClick={() => close()}>{t`Avbryt`}</Button>
        <Button onClick={() => submit()}>{t`Tillämpa`}</Button>
      </Group>
    </Flex>
  );
};

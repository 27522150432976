import { NavigationItem } from './NavigationItem';
import { Routes } from './Routes';

export const Navigation: NavigationItem = {
  [Routes.Applications]: {
    label: 'Granska',
    link: '/applications',
  },
  [Routes.Payments]: {
    label: 'Betala',
    link: '/payments',
  },
  [Routes.Approvals]: {
    label: 'Besluta',
    link: '/approvals',
  },
  [Routes.Archive]: {
    label: 'Arkiv',
    link: '/archive',
  },
  [Routes.ApplicationPeriods]: {
    label: 'Perioder',
    link: '/applicationperiods',
  },
  [Routes.Settings]: {
    label: 'Inställningar',
    link: '/settings',
  },
  [Routes.ApplicationReports]: {
    label: 'Återrapporter',
    link: '/applicationreports',
  },
  [Routes.Reviews]: {
    label: 'Granskningar',
    link: '/reviews',
  },
  [Routes.Documents]: {
    label: 'Dokument',
    link: '/documents',
  },
};

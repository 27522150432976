import { Stack, Title, Text } from '@mantine/core';
import { IconDownload, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { DisplayButtons } from '../../components/LiitGrid/DisplayButtons';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { download } from '../../utils/download';
import { handleError } from '../../utils/handleError';
import { PaymentBatchesViewColumns } from './PaymentBatchesViewColumns';
import { openConfirmModal } from '@mantine/modals';
import { t } from 'ttag';
import { PaymentBatchType } from '../../models/PaymentBatchType';
import { useNavigate } from 'react-router-dom';

export const PaymentBatchesView: React.FC = () => {
  const [paymentFiles, setPaymentFiles] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const navigate = useNavigate();

  const refreshPaymentsBatches = async () => {
    const result = await api.getPaymentBatches(selectedOrganisation!.id);
    setPaymentFiles(result);
  };

  const downloadPaymentFile = async (id: string) => {
    if (selectedOrganisation) {
      const response = await api.downloadPaymentBatchFile(selectedOrganisation.id, id);

      if (response.ok) {
        const buffer = await response.arrayBuffer();
        await download(buffer, 'payments.xml');
      } else {
        await handleError(response);
      }
    }
  };

  const deletePaymentBatch = async (id: string) => {
    if (selectedOrganisation) {

      return openConfirmModal({
        title: <Title order={3}>{t`Ta bort`}</Title>,
        children: (
          <Stack>
            <Text size={'sm'}>{t`Vill du ta bort raden?`}</Text>
          </Stack>
        ),
        labels: { confirm: 'OK', cancel: 'Avbryt' },
        onConfirm: async () => {

          await api.deletePaymentBatch(selectedOrganisation.id, id);

          await refreshPaymentsBatches();
        },
      });


    }
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshPaymentsBatches();

      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  const gridButtons: DisplayButtons[] = [
    {
      name: 'delete',
      tooltip: t`Ta bort`,
      icon: <IconTrash size={24} />,
      onClick: (id: string) => deletePaymentBatch(id),
    },
    {
      name: 'download',
      tooltip: t`Ladda ner`,
      icon: <IconDownload size={24} />,
      onClick: (id: string) => downloadPaymentFile(id),
      isDisabled: (row: any) => {
        return row.type === PaymentBatchType.Manual;
      },
    },
  ];

  return (
    <>
      <GrantMeContainer>
        <Stack gap={0}>
          <GrantMeTitleContainer>
            <Title order={3}>Betalade</Title>
          </GrantMeTitleContainer>


          <LiitGrid
            data={paymentFiles}
            columnInfo={PaymentBatchesViewColumns()}
            onRowClick={(id: string) => {
              navigate(`/paymentbatches/${id}`);
            }}
            highligthOnHoover
            displayButtons={gridButtons}
            fetchingData={isFetching}
          />

        </Stack>
      </GrantMeContainer>
    </>
  );
};

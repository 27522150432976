import React, { useEffect, useState } from 'react';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { Form } from '../../../models/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Popover, Text, useMantineTheme } from '@mantine/core';
import { FormBuilder } from '../../../components/formbuilder/FormBuilder';
import { t } from 'ttag';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { createStyles } from '@mantine/emotion';
import { showFailNotification, showSuccessNotification } from '../../../utils/notificationHelper';

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  content: {
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing.xl,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '24px',
    marginRight: theme.spacing.xl,
    boxShadow: `0 -5px 3px -3px ${theme.colors.gray[5]}`,
    zIndex: 20,
  },
}));

export const FormView: React.FC = () => {
  const api = useApi();
  const [form, setForm] = useState<Form | undefined>(undefined);
  const { selectedOrganisation } = useSession();
  const { formId } = useParams();
  const theme = useMantineTheme();
  const [json, setJson] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [showFormErrors, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const fetchData = async () => {
      const formResponse = await api.getForm(selectedOrganisation!.id, formId);
      setForm(formResponse);
      setJson(JSON.stringify(formResponse.json));
    };

    fetchData();
  }, [selectedOrganisation]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (selectedOrganisation) {
  //       if (formData) {
  //         setJson(formData);  
  //       } else {
  //         const result = await api.getApplicationPeriodForm(
  //           selectedOrganisation.id,
  //           applicationPeriod.id,
  //         );
  //         setJson(JSON.stringify(result));
  //       }
  //     }
  //   };

  //   if (isPresent(selectedOrganisation && isPresent(applicationPeriod))) {
  //     fetchData();
  //   }
  // }, [applicationPeriod]);

  const onJsonChanged = (data: string) => {
    setJson(data);
    if (form) {
      const f = { ...form, json: JSON.parse(data) };
      setForm(f);
    }
  };

  const onSave = async () => {
    if (!selectedOrganisation || !form) {
      return;
    }

    try {
      await api.updateForm(
        selectedOrganisation.id,
        form,
      );
      showSuccessNotification(t`Klart`, t`Formulär sparat`);

    } catch {
      showFailNotification(t`Fel`, t`Något gick fel vid sparning av formulär`);
    }
  };

  const onCancel = () => {
    //navigate('/settings/forms');
    history.go(-1);
  };

  const save = async (closeOnSave: boolean) => {
    // if (!selectedOrganisation || !json) {
    //   return;
    // }

    // try {
    //   await api.updateApplicationPeriodForm(
    //     selectedOrganisation.id,
    //     applicationPeriod.id,
    //     JSON.parse(json),
    //   );
    //   showSuccessNotification(t`Klart`, t`Formulär sparat`);

    //   if (closeOnSave) {
    //     onDone();
    //   }
    // } catch {
    //   showFailNotification(t`Fel`, t`Något gick fel vid sparning av formulär`);
    // }
  };

  if (!json) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const getFormStatus = () => {
    if (formErrors) {
      const isValid = formErrors.length === 0;
      if (isValid) {
        return <IconCircleCheck size={36} color={theme.colors.green[5]} />;
      } else {
        return (
          <Popover width={360} position={'top'} withArrow shadow={'md'} opened={showFormErrors}>
            <Popover.Target>
              <Box w={36} h={36}>
                <IconCircleX
                  onMouseEnter={open}
                  onMouseLeave={close}
                  size={36}
                  color={theme.colors.red[5]}
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Flex gap={16} direction={'column'} align={'center'}>
                {formErrors.length > 0 && (
                  <Flex direction={'column'} align={'center'}>
                    <Text
                      ta={'center'}
                      size={'sm'}
                      fw={'bold'}>{t`Element som saknas`}</Text>
                    {formErrors.map((e) => {
                      return (
                        <Text color={'red.5'} size={'sm'} fw={500} key={'error_' + e}>
                          {e}
                        </Text>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </Popover.Dropdown>
          </Popover>
        );
      }
    }
  };

  const formIsValid = formErrors.length === 0;

  if (!form) {
    return <>Loading ...</>;
  }

  return <FormBuilder
    json={json}
    onChanged={onJsonChanged}
    onSave={onSave}
    onCancel={onCancel}
    title={form.name}
    applicantType={form.applicantType}
    paymentOption={form.paymentOption}
    setFormStatus={setFormErrors}
  />;

  {/* <Group justify="space-between" w={'100%'} align={'center'}>
          <Button
            disabled={!formIsValid}
            type="submit"
            onClick={async () => {
              await save(true);
            }}>
            {t`Spara och stäng`}
          </Button>
          {getFormStatus()}
          <Group justify="flex-end">
            <Button variant="outline"> */}
  {/* <Button variant="outline" onClick={() => onDone()}> */ }
  {/* {t`Avbryt`} 
            </Button>
            <Button
              disabled={!formIsValid}
              type="submit"
              onClick={async () => {
                await save(false);
              }}>
              {t`Spara`}
            </Button>
          </Group>
        </Group> */}

};
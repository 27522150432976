import { Group, Loader, ScrollArea } from '@mantine/core';
import { Document } from 'react-pdf';
import React, { useEffect, useState } from 'react';
import { Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

interface PDFViewerProps {
  getData: () => Promise<string | null>;
  filename: string;
  navigate?: (direction: string) => void;
  scale: number;
  rotation: number;
  pdfId: string;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ getData, scale, rotation, pdfId }) => {
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [pages, setPages] = useState(0);

  useEffect(() => {
    getData()
      .then((data) => {
        if (data) {
          setObjectUrl(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pdfId]);

  const loadingUI = (
    <Group justify={'center'} pt={'xl'}>
      <Loader size={'xl'} />
    </Group>
  );

  if (!objectUrl) {
    return loadingUI;
  }

  return (
    <ScrollArea
      style={{ height: '100%', isolation: 'isolate' }}
      pl={'sm'}
      pb={'md'}
      pt={'md'}
      pr={'sm'}>
      <Document
        loading={loadingUI}
        file={objectUrl}
        onLoadSuccess={async (pdf) => {
          setPages(pdf?.numPages ?? 0);
        }}>
        {Array.from({ length: pages }, (_, index) => {
          return (
            <Page
              rotate={rotation}
              scale={scale}
              loading={''}
              width={800}
              key={'pages_' + index}
              pageIndex={index + 1}
              pageNumber={index + 1}
            />
          );
        })}
      </Document>
    </ScrollArea>
  );
};

import { Group, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { RenderCell, CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { t } from 'ttag';
import { Role } from '../../models/Role';
import { FolderPermissionLevel } from '../../models/FolderPermissionLevel';

export const RenderDocumentPermissionsCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { cellValue, rowValues } = rowInformation;

  const getPermissionLevel = (permissionLevel: number): string => {
    switch (permissionLevel) {
      default:
      case FolderPermissionLevel.None:
        return t`Inga`;

      case FolderPermissionLevel.Read:
        return t`Läs`;

      case FolderPermissionLevel.Write:
        return t`Fullständiga`;
    }
  };

  const getRole = (role: Role): string => {
    switch (role) {
      default:
      case Role.Reviewer:
        return t`Granskare`;

      case Role.Approver:
        return t`Beslutsfattare`;
    }
  };

  const getPermissionText = (role: Role, permissionLevel: number): string => `${getRole(role)}: ${getPermissionLevel(permissionLevel)}`;

  const permissionTexts: string[] = cellValue ? cellValue.map((permission: { role: Role, permissionLevel: number }) => getPermissionText(permission.role, permission.permissionLevel)).sort() : [];

  return (
    <Group>
      {permissionTexts.map((text: string, index) => (
        <Text size='sm' key={'permissiontext-' + index}>{text}</Text>
      ))}
    </Group>
  );
};

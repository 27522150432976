import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Automatically restore scroll to top on navigating to a new page. By default
 * the window will be scrolled but you can use a ref to scroll a specific
 * container.
 *
 * @example
 *
 *   // Automatically scrolls the window to top on route change
 *   useScrollToTop();
 *
 *   // Automatically scrolls referenced div on route change
 *   const { ref } = useScrollToTop();
 *   <div ref={ref}></div>
 **/
export const useScrollToTop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const element = ref.current ?? window;
    element.scrollTo(0, 0);
  }, [location.pathname]);

  return {
    ref,
  };
};

import { DateTime } from 'luxon';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { ApplicationPeriodType } from '../../models/ApplicationPeriodType';
import { ProductVariant } from '../../models/ProductVariant';

export interface ApplicationPeriodFormValues {
  startDate: Date;
  endDate: Date | null;
  title: string;
  id: string;
  description: string;
  isPublished: boolean;
  isReviewable: boolean;
  // paymentOptionSelectValue: string | null;
  criteriaIds: Array<string>;
  reviewerIds: Array<string>;
  // applicantTypeSelectValue: string | null;
  hasApplications: boolean;
  typeSelectValue: string;
  previousApplicationPeriodId: string | null;
  formId: string;
  budget: number | null;
  isArchived: boolean;
  maxCriteriaScore: number;
  mailTextApplicationReceived: string;
  mailTextApplicationApproved: string;
  mailTextApplicationRejected: string;
  mailTextRequisitionReminder: string;
  mailTextCompletionRequested: string;
  mailTextCompletionReceived: string;
  mailSubjectApplicationReceived: string;
  mailSubjectApplicationApproved: string;
  mailSubjectApplicationRejected: string;
  mailSubjectRequisitionReminder: string;
  mailSubjectCompletionRequested: string;
  mailSubjectCompletionReceived: string;
}

export const applicationPeriodToFormValues = (
  period: ApplicationPeriod | null,
  variant: ProductVariant,
): ApplicationPeriodFormValues => {
  return {
    startDate: period ? DateTime.fromISO(period.startDate).toJSDate() : DateTime.now().toJSDate(),
    endDate:
      period && period.endDate
        ? DateTime.fromISO(period.endDate).toJSDate()
        : DateTime.now().plus({ months: 1 }).toJSDate(),
    title: period ? period.title : '',
    budget: period ? period.budget : null,
    id: period ? period.id : '',
    formId: period ? period.formId : '',
    description: period ? (period.description ? period.description : '') : '',
    isPublished: period ? period.isPublished : false,
    isReviewable: period ? period.isReviewable : variant === ProductVariant.Large ? true : false,
    //paymentOptionSelectValue: period ? period.paymentOption.toString() : null,
    criteriaIds: period ? period.criteriaIds : ([] as Array<string>),
    reviewerIds: period ? period.reviewerIds : ([] as Array<string>),
    //applicantTypeSelectValue: period ? period.applicantType.toString() : null,
    hasApplications: period ? period.hasApplications : false,
    typeSelectValue: period ? period.type.toString() : ApplicationPeriodType.Default.toString(),
    previousApplicationPeriodId: period ? period.previousApplicationPeriodId : null,
    isArchived: false,
    maxCriteriaScore: period ? period.maxCriteriaScore : 5,
    mailTextApplicationReceived: period?.mailTextApplicationReceived ?? 'Tack för din ansökan.',
    mailTextApplicationApproved:
      period?.mailTextApplicationApproved ?? 'Din ansökan har blivit beviljad!',
    mailTextApplicationRejected:
      period?.mailTextApplicationRejected ?? 'Din ansökan har blivit tyvärr blivit avslagen.',
    mailTextRequisitionReminder:
      period?.mailTextRequisitionReminder ??
      'Glöm inte att du behöver rekvirera pengar för din ansökan.',
    mailTextCompletionRequested:
      period?.mailTextCompletionRequested ??
      'Du behöver komplettera din ansökan för stipendie {{periodname}}.',
    mailTextCompletionReceived:
      period?.mailTextCompletionReceived ??
      'Vi har mottagit din komplettering för ansökan {{number}} {{title}}.',
    mailSubjectApplicationApproved:
      period?.mailSubjectApplicationApproved ?? 'Du har nu fått ett beslut på ansökan {{number}} till {{organisation}}',
    mailSubjectApplicationRejected:
      period?.mailSubjectApplicationRejected ?? 'Du har nu fått ett beslut på ansökan {{number}} till {{organisation}}',
    mailSubjectRequisitionReminder:
      period?.mailSubjectRequisitionReminder ?? 'Påminnelse rekvirering ansökan {{number}}',
    mailSubjectCompletionRequested:
      period?.mailSubjectCompletionRequested ?? 'Komplettering begärd för ansökan {{number}}',
    mailSubjectCompletionReceived:
      period?.mailSubjectCompletionReceived ?? 'Komplettering för ansökan {{number}} mottagen',
    mailSubjectApplicationReceived:
      period?.mailSubjectApplicationReceived ?? 'Ansökan {{number}} mottagen',
  };
};

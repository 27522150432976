import React, { useEffect, useState } from 'react';
import { Badge, Button, Group, Select, Stack, Tabs, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ApplicationPeriodEditForm } from '../ApplicationPeriodEditForm';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import {
  ApplicationPeriodListColumns1,
  ApplicationPeriodListColumns,
} from './ApplicationPeriodListColumns';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { LiitDrawer } from '../../../components/LiitDrawer/LiitDrawer';
import { ApplicationPeriodStatus } from '../../../models/ApplicationPeriodStatus';
import { ApplicationPeriodResult } from '../../../models/ApplicationPeriodResult';
import { LiitDropdown } from '../../../components/LiitDropdown/LiitDropdown';
import { createStyles } from '@mantine/emotion';
import { showNotification } from '@mantine/notifications';
import { showInformationNotification } from '../../../utils/notificationHelper';

export const BORDER_COLOR = '#cbd0dd'; //gray[3]

export const useStyles = createStyles(() => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ApplicationPeriodList: React.FC = () => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const navigate = useNavigate();
  const [periodStatus, setPeriodStatus] = useState<string>(ApplicationPeriodStatus.Ongoing.toString());
  const [applicationPeriods, setApplicationPeriods] = useState<ApplicationPeriodResult[] | undefined>(undefined);
  const { classes } = useStyles();

  const [newOpened, setNewOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const createApplicationPeriod = async () => {
    const forms = await api.getForms(selectedOrganisation!.id);
    if (forms.length === 0) {
      showInformationNotification(undefined, 'För att kunna skapa en period måste du först ha ett formulär. Gå till Inställningar/Formulär för att skapa ett.');
    } else {
      setNewOpened(true);
    }
  };

  const viewApplicationPeriod = (id: string) => {
    navigate(`/applicationperiods/${id}`);
  };

  const refreshApplicationPeriods = async () => {
    setNewOpened(false);

    if (selectedOrganisation) {
      setIsFetching(true);
      const applicationPeriodsResult = await api.getApplicationPeriods(selectedOrganisation.id);

      setApplicationPeriods(applicationPeriodsResult.filter((x) => x.status.toString() === periodStatus));

      setIsFetching(false);
    }
  };

  const deleteApplicationPeriod = async (id: string) => {
    if (selectedOrganisation) {
      await api.deleteApplicationPeriod(selectedOrganisation.id, id);
      await refreshApplicationPeriods();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await refreshApplicationPeriods();
    };

    fetchData();
  }, [selectedOrganisation, periodStatus]);

  const columnInfos =
    periodStatus === ApplicationPeriodStatus.Ongoing.toString() ||
    periodStatus === ApplicationPeriodStatus.Upcoming.toString()
      ? ApplicationPeriodListColumns
      : ApplicationPeriodListColumns1;

  if (!selectedOrganisation) return <></>;

  return (
    <GrantMeContainer size={'xl'}>
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Title order={3}>Ansökningsperioder</Title>
          <Button size={'sm'} onClick={async () => createApplicationPeriod()} leftSection={<IconPlus />}>
            Ny Ansökningsperiod
          </Button>
        </GrantMeTitleContainer>
        <Group pl={42} className={classes.filterGroup} gap={'xl'} h={48}>
          <LiitDropdown
            menuItems={[ { id: ApplicationPeriodStatus.Ongoing.toString(), text: 'Pågående perioder' },
              { id: ApplicationPeriodStatus.Upcoming.toString(), text: 'Kommande perioder' },
              { id: ApplicationPeriodStatus.Completed.toString(), text: 'Avslutade perioder' },
            ]}
            onChange={(periodId) => {
              if (periodId) {
                setPeriodStatus(periodId);
              }
            }}
            selectedId={periodStatus}
            showColorBox={false}
          /> 
        </Group>        
        <LiitGrid
          data={applicationPeriods}
          columnInfo={columnInfos()}
          onRowClick={viewApplicationPeriod}
          fetchingData={isFetching}
        />
      </Stack>
      <LiitDrawer size={'50%'} opened={newOpened} onClose={() => setNewOpened(false)}>
        <ApplicationPeriodEditForm
          applicationPeriod={null}
          selectedOrganisation={selectedOrganisation}
          onClose={() => setNewOpened(false)}
          onSave={refreshApplicationPeriods}
        />
      </LiitDrawer>
    </GrantMeContainer>
  );
};

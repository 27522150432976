import { ActionIcon, ActionIconProps, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

type LiitActionIconProps = {
  tooltip: string,
  icon: React.ReactElement,
  onClick: () => void,
} & ActionIconProps;

export const LiitActionIcon: React.FC<LiitActionIconProps> = ({ tooltip, icon, onClick, ...props }) => {
  const theme = useMantineTheme();
  return (
    <Tooltip withArrow label={tooltip} color={`${theme.primaryColor}.5`}>
      <ActionIcon
        color={theme.primaryColor}
        variant="subtle"
        {...props} onClick={() => onClick()}>
        {icon}
      </ActionIcon>
    </Tooltip>
  );
};
import { useIsAuthenticated } from '@azure/msal-react';
import { Stack, Title, Button, Box, Flex, Group, Checkbox } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { FormElementType } from '../../components/formbuilder/models/FormElementType';
import { FormModel } from '../../components/formbuilder/models/FormModel';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { LanguageCode } from '../../models/LanguageCode';
import { download } from '../../utils/download';
import { handleError } from '../../utils/handleError';
import { t } from 'ttag';

interface ExportApplicationsProps {
  applicationPeriod: ApplicationPeriod;
  onComplete: () => void;
}

export const ExportApplications: React.FC<ExportApplicationsProps> = ({
  applicationPeriod,
  onComplete,
}) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [formModel, setFormModel] = useState<FormModel>();

  const [staticFields, setStaticFields] = useState<string[]>([]);
  const [formFields, setFormFields] = useState<string[]>([]);
  const [applicationFields, setApplicationFields] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation) {
        if (applicationPeriod) {
          const result = await api.getForm(
            selectedOrganisation.id,
            applicationPeriod.formId,
          );

          setFormModel(result.json);
        }
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [selectedOrganisation, isAuthenticated]);

  const exportApplications = async () => {
    if (selectedOrganisation && applicationPeriod) {
      const response = await api.exportApplications(selectedOrganisation.id, {
        applicationPeriodId: applicationPeriod.id,
        applicationFields: applicationFields,
        staticFields: staticFields,
        formFields: formFields,
      });

      if (response.ok) {
        const buffer = await response.arrayBuffer();
        await download(buffer, 'export.xlsx');
      } else {
        await handleError(response);
      }
    }
  };

  const getElements = () => {
    if (formModel) {
      const elements = formModel.pages.flatMap((page) => page.elements);
      return elements.filter(
        (x) =>
          x.type == FormElementType.TextArea ||
          x.type == FormElementType.Dropdown ||
          x.type == FormElementType.RadioButtonGroup,
      );
    }
    return [];
  };

  return (
    <Flex direction={'column'} w={'100%'}>
      <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Stack>
          <Title order={3}>Exportera</Title>
          Välj de fält du vill exportera:
          <Stack>
            <Checkbox.Group label={t`Ansökan`} value={applicationFields} onChange={setApplicationFields}>
              <Stack gap={8} pt={8}>
                <Checkbox value="Status" label={t`Status`} />
                <Checkbox value="Number" label={t`Diarienummer`} />
                <Checkbox value="SubmittedUtc" label={t`Inkom datum`} />
              </Stack>
            </Checkbox.Group>

            <Checkbox.Group
              label={t`Sökande/Kontaktperson`}
              value={staticFields}
              onChange={setStaticFields}>
              <Stack gap={8} pt={8}>
                <Checkbox value="ApplicantFirstName" label={t`Förnamn`} />
                <Checkbox value="ApplicantLastName" label={t`Efternamn`} />
                <Checkbox
                  value="ApplicantIdentityNumber"
                  label={t`Person- eller organisationsnummer`}
                />
                <Checkbox value="ApplicantClearingNumber" label={t`Clearingnummer`} />
                <Checkbox value="ApplicantAccountNumber" label={t`Kontonummer`} />
                <Checkbox value="ApplicantBgNumber" label={t`Bankgironummer`} />
                <Checkbox value="ApplicantPgNumber" label={t`Postgironummer`} />
                <Checkbox value="ContactPersonEmail" label={t`E-post`} />
                <Checkbox value="ContactPersonPhone" label={t`Telefon`} />
                <Checkbox value="ContactPersonFirstName" label={t`Förnamn kontaktperson`} />
                <Checkbox value="ContactPersonLastName" label={t`Efternamn kontaktperson`} />
              </Stack>
            </Checkbox.Group>

            <Checkbox.Group label={t`Övriga`} value={formFields} onChange={setFormFields}>
              <Stack gap={8} pt={8}>
                {getElements().map((x) => {
                  return (
                    <Checkbox
                      key={x.id}
                      value={x.id}
                      label={
                        x.label.find((y) => y.language === LanguageCode.sv)?.text ??
                        '(Rubrik saknas)'
                      }
                    />
                  );
                })}
              </Stack>
            </Checkbox.Group>
          </Stack>
        </Stack>
      </Box>
      <Group justify={'end'} pt={'xl'}>
        <Button onClick={() => exportApplications()}>Exportera till Excel</Button>
      </Group>
    </Flex>
  );
};

import React from 'react';
import { Container, Anchor, Group, Burger } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { OrganisationAndProfileMenu } from './OrganisationAndProfileMenu';
import { SearchInput } from '../features/search/SearchInput';
import { createStyles } from '@mantine/emotion';
import { BURGER_TRIGGER } from './Layout';

export const HEADER_HEIGHT = 64;
export const STICKY_HEIGHT = 105;

const useStyles = createStyles((theme, _, u) => ({
  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: 8,
    padding: 16,
    border: '1px solid lightgray',
    backgroundColor: theme.colors[theme.primaryColor][8],

    [u.largerThan(BURGER_TRIGGER)]: {
      display: 'none',
    },
  },

  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  burger: {
    [u.largerThan(BURGER_TRIGGER)]: {
      display: 'none',
    },
  },

  links: {
    height: HEADER_HEIGHT,
    display: 'flex',
    paddingRight: '16px',

    [u.smallerThan('md')]: {
      display: 'none',
    },
  },

  mainLinks: {
    flex: 1,
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.colors.dark[0],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',
    letterSpacing: '1.5px',

    '&:hover': {
      color: theme.white,
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.white,
    borderBottomColor: theme.colors[theme.primaryColor][3],
  },

  header: {
    height: HEADER_HEIGHT,
    border: 0,
    background: theme.colors[theme.primaryColor][8],
    position: 'static',
  },

  organisationSelector: {
    display: 'flex',
    flexDirection: 'row',
  },

  logo: {
    content: 'url("/grantme_light_transparent.png")',
    maxWidth: 192,

    [u.smallerThan(BURGER_TRIGGER)]: {
      content: 'url("/logo512_light.png")',
      maxHeight: HEADER_HEIGHT - 32,
    },
  },
}));

interface MenuHeaderProps {
  simpleMode?: boolean;
  menuOpened: boolean;
  toggleMenu: () => void;
}

export const MenuHeader: React.FC<MenuHeaderProps> = ({ simpleMode, menuOpened, toggleMenu }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <Container w={'100%'} maw={'unset'} className={classes.inner}>
        <Group justify={'space-between'} style={{ width: '100%' }}>
          <Group>
            {!simpleMode && (
              <AuthenticatedTemplate>
                <Burger
                  opened={menuOpened}
                  onClick={toggleMenu}
                  className={classes.burger}
                  size={'md'}
                  color={'#FFFFFF'}
                />
              </AuthenticatedTemplate>
            )}
            <Anchor component={Link} to="/">
              <img className={classes.logo}></img>
            </Anchor>
          </Group>
          {!simpleMode && (
            <Group>
              <AuthenticatedTemplate>
                <SearchInput />
                <OrganisationAndProfileMenu />
              </AuthenticatedTemplate>
            </Group>
          )}
        </Group>
      </Container>
    </div>
  );
};

export const download = async (data: string | ArrayBuffer, filename: string): Promise<void> => {
  if (!data) {
    return;
  }

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
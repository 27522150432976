import { Box, Group, Menu, ScrollArea, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconFilter, IconSquare, IconSquareFilled, IconWashDryOff } from '@tabler/icons-react';
import React, { useState } from 'react';
import { t } from 'ttag';

const useStyles = createStyles((theme) => ({
  menuItemWrapper: {
    backgroundColor: theme.white,
    cursor: 'pointer',
    absolute: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },

  truncate: {
    maxWidth: '240px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      maxWidth: '140px',
    },
  },
}));

export interface LiitDropdownMenuItem {
  id: string;
  text: string;
  color?: string;
  inverted?: string;
}

interface LiitMultiSelectDropdownProps {
  itemsDescription: string;
  emptyText?: string;
  menuItems: LiitDropdownMenuItem[] | null;
  selectedIds: string[];
  onChange: (periodId: string[]) => void;
  showColorBox?: boolean;
}

export const LiitMultiSelectDropdown: React.FC<LiitMultiSelectDropdownProps> = ({
  itemsDescription,
  emptyText,
  menuItems,
  selectedIds = [],
  onChange,
  showColorBox = false,
}) => {
  const { classes, theme } = useStyles();
  const largeScreen = useMediaQuery('(min-width: 1200px)');
  
  if (menuItems === null) {
    return null;
  }

  const getText = () => {
    if (selectedIds.length > 1) {
      return t`Flera ${itemsDescription} valda`;
    }

    if (selectedIds.length === 0) {
      return emptyText;
    }

    return menuItems.find((p) => p.id === selectedIds[0])?.text ?? emptyText;
  };

  const getCheckBox = (id: string, color?: string) => {
    let c = theme.colors[theme.primaryColor][7];
    if (showColorBox && color) {
      const col = color.split('.');
      c = theme.colors[col[0]][parseInt(col[1])];
    }

    if (selectedIds.includes(id)) {
      return <IconSquareFilled size={16} color={c} />;
    } else {
      return <IconSquare size={16} color={c} />;
    }
  };


  return (
    <div className={classes.menuItemWrapper}>
      <Menu
        closeOnItemClick={false}
        transitionProps={{ transition: 'pop' }}
        position={'bottom-start'}
        styles={{
          item: {
            padding: '6px 12px',
          },
          dropdown: {
            maxHeight: '370px',
          },
          
        }}>
        <Menu.Target>
          <Group gap={8}>
            {largeScreen ? (
              <Group gap={'xs'}>
                <Text size={'sm'} c={selectedIds.length === 0 ? 'dimmed' : theme.black}>
                  {getText()}
                </Text>
                <IconChevronDown size={14} color={theme.colors.dark[3]} />
              </Group>
            ) : (
              <IconFilter color={theme.colors[theme.primaryColor][7]} />
            )}
          </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <ScrollArea.Autosize mah={350} mx={'auto'}>
            {emptyText && (
              <Menu.Item
                key={'menu_item_all'}
                onClick={() => {
                  onChange([]);
                }}>
                <Group gap={'xs'}>
                  <IconWashDryOff size={16} color={theme.colors[theme.primaryColor][7]} />
                  <Text size={'sm'}>{emptyText}</Text>
                </Group>
              </Menu.Item>
            )}
            {menuItems?.map((p) => (
              <Menu.Item
                key={`menu_item_${p.id}`}
                onClick={() => {
                  if (selectedIds.includes(p.id)) {
                    const newIds = selectedIds.filter((id) => id !== p.id);
                    onChange(newIds);
                  } else {
                    onChange([...selectedIds, p.id]);
                  }
                }}>
                <Group gap={'xs'}>
                  {getCheckBox(p.id, p.color)}
                  <Text fz={'sm'}>{p.text}</Text>
                </Group>
              </Menu.Item>
            ))}
          </ScrollArea.Autosize>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

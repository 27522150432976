import { Grid, Text, Title } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { WayOfPayment } from '../../../../models/WayOfPayment';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapperWithLabel } from '../Common/FormInputWrapperWithLabel';
import { WayOfPaymentElementValue } from './WayOfPaymentValue';

export const ViewerWayOfPayment: React.FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  const typedValue = element.value as WayOfPaymentElementValue;

  const { wayOfPayment, clearingNumber, accountNumber, bgNumber, pgNumber, otherValue } =
    typedValue;

  return (
    <Grid gutter={'xs'} pt="xl">
      <Grid.Col span={12}>
        <Title order={4}>{t`Utbetalningsinformation`}</Title>
      </Grid.Col>

      {wayOfPayment === WayOfPayment.BankAccount && (
        <>
          <Grid.Col span={{ lg: 6 }}>
            <ViewerInputWrapperWithLabel
              label={t`Clearingnummer`}
              number={number}
              element={element}>
              <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
                {clearingNumber}
              </Text>
            </ViewerInputWrapperWithLabel>
          </Grid.Col>
          <Grid.Col span={{ lg: 6 }}>
            <ViewerInputWrapperWithLabel label={t`Kontonummer`} number={number} element={element}>
              <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
                {accountNumber}
              </Text>
            </ViewerInputWrapperWithLabel>
          </Grid.Col>
        </>
      )}
      {(wayOfPayment === WayOfPayment.PlusGiro || wayOfPayment === WayOfPayment.BankGiro) && (
        <Grid.Col span={{ lg: 12 }}>
          <ViewerInputWrapperWithLabel
            label={wayOfPayment === WayOfPayment.BankGiro ? t`Bankgiro` : t`Plusgiro`}
            number={number}
            element={element}>
            <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
              {wayOfPayment === WayOfPayment.BankGiro ? bgNumber : pgNumber}
            </Text>
          </ViewerInputWrapperWithLabel>
        </Grid.Col>
      )}
      {wayOfPayment === WayOfPayment.Other && (
        <Grid.Col span={{ lg: 12 }}>
          <ViewerInputWrapperWithLabel
            label={t`Annat utbetalningssätt`}
            number={number}
            element={element}>
            <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
              {otherValue}
            </Text>
          </ViewerInputWrapperWithLabel>
        </Grid.Col>
      )}
    </Grid>
  );
};

import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Stack, Title, Flex, Button, Group, Breadcrumbs, Anchor } from '@mantine/core';
import {
  IconFolderPlus,
  IconMoodEmpty,
  IconUpload,
} from '@tabler/icons-react';
import { t } from 'ttag';
import { FolderContent } from '../../models/FolderContent';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { PagedResult } from '../../models/PagedResult';
import { useDisclosure } from '@mantine/hooks';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { isMissing, isPresent } from 'utilitype';
import { FolderContentType } from '../../models/FolderContentType';
import { download } from '../../utils/download';
import { FolderPermission } from '../../models/FolderPermission';
import { FolderPermissionLevel } from '../../models/FolderPermissionLevel';
import { EditFolderForm } from './EditFolderForm';
import { DocumentUploadDrawer } from './DocumentUploadDrawer';
import { showConfirmDeleteModal } from '../../utils/notificationHelper';
import { DocumentPreviewer } from './DocumentPreviewer';
import { createGridButtons } from './GridButtons';
import { createDocumentListColumns } from './DocumentListColumns';

export const DocumentsList: React.FC = ({ }) => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const { selectedOrganisation } = useSession();
  const [editFolderOpened, { open: openEditFolder, close: closeEditFolder }] = useDisclosure(false);
  const [uploadFileOpened, { open: openUploadFile, close: closeUploadFile }] = useDisclosure(false);
  const [previewItem, setPreviewItem] = useState<FolderContent>({} as FolderContent);
  const [previewOpened, { open: openPreview, close: closePreview }] = useDisclosure(false);
  const api = useApi();

  const [folderContent, setfolderContent] = useState<FolderContent | null>(null);
  const [folderStructure, setFolderStructure] = useState<PagedResult<FolderContent> | undefined>(
    undefined,
  );
  const [folderPermissions, setFolderPermissions] = useState<Array<FolderPermission>>([]);
  const [isFetching, setIsFetching] = useState(true);
  const fetchFolderStructure = async () => {
    if (selectedOrganisation) {
      setIsFetching(true);
      const result = await api.getFolderStructure(selectedOrganisation.id, folderId);

      setFolderStructure(result.pagedResult);
      setFolderPermissions(result.folderPermissions);
      setIsFetching(false);
    }
  };

  const initialize = async () => {
    await fetchFolderStructure();
  };

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);

      await initialize();

      setIsFetching(false);
    };

    init();
  }, [folderId]);

  const folderUpdated = async (): Promise<void> => {
    setfolderContent(null);
    closeEditFolder();
    await fetchFolderStructure();
  };

  const createBreadcrumbAnchors = (): ReactNode => {
    let breadCrumbs = folderStructure?.pagination.breadCrumbs ?? [];

    if (breadCrumbs.length > 3) {
      breadCrumbs = breadCrumbs.slice(-4);
      breadCrumbs[0].name = '...';
    }

    return (
      <Breadcrumbs>
        {breadCrumbs.map((item, index) => (
          <Anchor
            component={Link}
            to={isPresent(item.id) ? `/documents/${item.id}` : '/documents/'}
            key={index}>
            {item.name}
          </Anchor>
        ))}
      </Breadcrumbs>
    );
  };

  const documentListColumns = createDocumentListColumns(selectedOrganisation);

  const downloadFile = async (id: string) => {
    if (selectedOrganisation) {
      const downloadData = await api.downloadDocumentStorageFile(selectedOrganisation.id, id);

      if (isPresent(downloadData) && folderStructure) {
        const filename = folderStructure.data.find((c) => c.id === id)?.name ?? 'unknown-filename';

        await download(downloadData, filename);
      }
    }
  };

  const deleteItem = async (id: string) => {
    if (selectedOrganisation) {
      const item = folderStructure?.data.find((content) => content.id === id) || null;

      if (isPresent(item)) {
        const confirmText = item.type === FolderContentType.Folder ?
          t`Vill du verkligen ta bort katalogen ${item.name} och alla underkataloger?` :
          t`Vill du verkligen ta bort filen ${item.name}?`;

        showConfirmDeleteModal(confirmText, async () => {
          setIsFetching(true);
          await api.deleteFolderItems(selectedOrganisation.id, id);
          await fetchFolderStructure();
        });
      }
    }
  };

  const editFolder = async (id: string) => {
    const clickedContent = folderStructure?.data.find((content) => content.id === id) || null;
    setfolderContent(clickedContent);

    openEditFolder();
  };

  const currentUserPermission = folderPermissions.find(
    (p) => p.role === selectedOrganisation?.role,
  );

  const openPreviewHandler = (id: string) => {
    const clickedContent = folderStructure?.data.find((content) => content.id === id);
    if (clickedContent) {
      setPreviewItem(clickedContent);
      openPreview();
    }
  };

  const gridButtons = createGridButtons(
    selectedOrganisation,
    currentUserPermission,
    folderStructure,
    downloadFile,
    editFolder,
    deleteItem,
    openPreviewHandler,
  );

  return (
    <GrantMeContainer>
      <LiitDrawer
        onClose={() => {
          closeEditFolder();
          setfolderContent(null);
        }}
        opened={editFolderOpened}
        titleText={t`Skapa katalog`}
        size="md"
        padding="lg">
        <EditFolderForm
          onSave={folderUpdated}
          parentFolderId={folderId}
          folderContent={folderContent}
        />
      </LiitDrawer>

      <LiitDrawer
        onClose={() => {
          closeEditFolder();
          setfolderContent(null);
        }}
        opened={editFolderOpened}
        titleText={t`Redigera katalog`}
        size="md"
        padding="lg">
        <EditFolderForm
          onSave={folderUpdated}
          parentFolderId={folderId}
          folderContent={folderContent}
        />
      </LiitDrawer>

      <DocumentPreviewer
        activeItem={previewItem}
        closePreview={closePreview}
        previewOpened={previewOpened}
        downloadArrayBuffer={async () => {
          if (selectedOrganisation) {
            return api.downloadDocumentStorageFile(selectedOrganisation.id, previewItem.id);
          }

          return new ArrayBuffer(0);
        }}
      ></DocumentPreviewer>

      <DocumentUploadDrawer
        open={uploadFileOpened}
        onClose={() => closeUploadFile()}
        onFileUploaded={() => fetchFolderStructure()}
      />

      <Stack gap={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Dokument`}</Title>
          {createBreadcrumbAnchors()}
          <Group>
            {(currentUserPermission?.permissionLevel === FolderPermissionLevel.Write ||
              selectedOrganisation?.role === 2) && (
                <>
                  <Button
                    size="sm"
                    variant="subtle"
                    onClick={() => openEditFolder()}
                    leftSection={<IconFolderPlus size={14} />}>
                    {t`Skapa katalog`}
                  </Button>
                  <Button
                    disabled={isMissing(folderId)}
                    size="sm"
                    variant="subtle"
                    onClick={() => openUploadFile()}
                    leftSection={<IconUpload size={14} />}>
                    {t`Ladda upp fil`}
                  </Button>
                </>
              )}
          </Group>
        </GrantMeTitleContainer>

        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={folderStructure}
            columnInfo={documentListColumns}
            emptyText={
              isMissing(folderId)
                ? t`Här var det tomt. Skapa en katalog för att komma igång!`
                : t`Här var det tomt. Skapa en katalog eller ladda upp en fil för att komma igång!`
            }
            emptyIcon={IconMoodEmpty}
            fetchingData={isFetching}
            displayButtons={gridButtons}
            onRowClick={(id: string) => {
              const clickedContent = folderStructure?.data.find((content) => content.id === id);

              if (clickedContent?.type === FolderContentType.Folder) {
                navigate(`/documents/${id}`);
              }
            }}
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};

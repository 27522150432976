import { createStyles } from '@mantine/emotion';
import { BORDER_COLOR } from '../../../theme';

export const applicationViewStyles = createStyles((theme) => ({
  scrollContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    padding: '16px',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'row',
    background: theme.colors.gray[0],
  },
  formContainer: {
    maxWidth: '95%',
    minHeight: '100%',
    padding: theme.spacing.xl,
    paddingTop: theme.spacing.sm,
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  reviewControls: {
    backgroundColor: theme.colors.gray[1],
    borderLeft: `1px solid ${BORDER_COLOR}`,
    overflow: 'auto',
    flex: 1,
  },
  aAndA: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  attachmentsContainer: {
    background: theme.colors.gray[0],
    overflow: 'hidden',
  },
}));

import React from 'react';
import { Button, Group } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

// const HEADER_HEIGHT = 84;

const useStyles = createStyles((theme) => ({
  controlsWrapper: {
    // position: 'absolute',
    // width: '100%',
    // height: '100px',
    // bottom: 0,
    // left: 0,
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: theme.colors[theme.primaryColor][8],
  },
}));

interface FormRunnerStepsControlsProps {
  onNext: () => void;
  onPrev: () => void;
  active: number;
  numberOfPages: number;
  moveUpNavigation?: number;
}

export const FormRunnerStepsControls: React.FC<FormRunnerStepsControlsProps> = ({ onPrev, onNext, active, numberOfPages, moveUpNavigation }) => {
  const { classes } = useStyles();

  const isFistPage = active === 0;
  const isLastPage = active >= numberOfPages - 1;

  return (
    <div className={classes.controlsWrapper} style={{ bottom: moveUpNavigation ?? 0 }}>
      <Group justify={'center'}>
        <Button variant="default" onClick={onPrev} disabled={isFistPage}>
          Föregående
        </Button>
        <Button onClick={onNext} disabled={isLastPage}>
          Nästa
        </Button>
      </Group>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Title, Group, Divider, Flex } from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { IconMoodEmpty } from '@tabler/icons-react';
import { ApplicationsListColumns } from './ApplicationsListColumns';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { t } from 'ttag';
import { ApplicationItem } from '../../../models/ApplicationItem';
import { PagedResult, PaginationModel } from '../../../models/PagedResult';
import { setColorsByDistinctId } from '../../../components/LiitGrid/SetColorsByDistinctId';
import { TableState } from '../../../components/LiitGrid/TableSortProps';
import { Tag } from '../../../models/Tag';
import { DebouncedTextInput } from '../../../components/DebouncedTextInput';
import { useApplicationQueryParameters } from '../../../hooks/useApplicationQueryParameters';
import { createStyles } from '@mantine/emotion';
import { LiitMultiSelectDropdown } from '../../../components/LiitMultiSelectDropdown/LiitMulltiSelectDropdown';
import { ApplicationStatus } from '../../../models/ApplicationStatus';

export const BORDER_COLOR = '#cbd0dd';

export const useStyles = createStyles(() => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ApplicationsList: React.FC = ({ }) => {
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const [applications, setApplications] = useState<PagedResult<ApplicationItem> | undefined>(
    undefined,
  );
  const [applicationPeriods, setApplicationPeriods] = useState<LiitDropdownMenuItem[] | null>(null);
  const [tags, setTags] = useState<Tag[]>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [parameters, setParameters, defaultParameters] = useApplicationQueryParameters();

  const keyword = new URLSearchParams(useLocation().search).get('keyword');

  const setColorsbyFormId = (items: ApplicationItem[], periods: LiitDropdownMenuItem[]) => {
    items.forEach((application) => {
      const { applicationPeriodId } = application;
      const colorItem = periods.find((p) => p.id === applicationPeriodId);
      application.categoryColor = {
        color: colorItem?.color ?? 'white',
        inverted: colorItem?.inverted ?? 'black',
      };
    });
  };

  const initialize = async () => {
    const applicationPeriodsResponse = await api.getApplicationPeriods(selectedOrganisation!.id);
    const colors = setColorsByDistinctId(
      theme,
      applicationPeriodsResponse.map((x) => x.id),
    );
    const preiodsInList = applicationPeriodsResponse.map((x) => ({
      id: x.id,
      text: x.title,
      color: colors[x.id].color,
      inverted: colors[x.id].inverted,
    }));

    setApplicationPeriods(preiodsInList);

    const tagsResponse = await api.getTags(selectedOrganisation!.id);
    setTags(tagsResponse);

    setIsInitialized(true);
  };

  const fetchApplications = async () => {
    const response = await api.queryApplications(selectedOrganisation!.id, parameters);

    const applicationList = response.data;
    const pagedApplications = {} as PagedResult<any>;
    pagedApplications.data = applicationList;
    pagedApplications.pagination = {
      currentPage: parameters.page,
      totalCount: response.count,
      totalPages: Math.ceil(response.count / parameters.pageSize),
    } as PaginationModel;

    setApplications(pagedApplications);
  };

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);

      await initialize();

      setIsFetching(false);
    };

    if (keyword) {
      const p = { ...defaultParameters, keyword: keyword };
      setParameters(p);
    }

    init();
  }, []);

  useEffect(() => {
    if (applications && applications.data && applicationPeriods) {
      setColorsbyFormId(applications.data, applicationPeriods!);
    }
  }, [applications, applicationPeriods]);

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);
      fetchApplications();
      setIsFetching(false);
    };

    if (applicationPeriods && parameters) {
      init();
    }
  }, [isInitialized, parameters]);

  return (
    <GrantMeContainer>
      <Stack gap={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Ansökningar`}</Title>
        </GrantMeTitleContainer>
        <Group pl={42} className={classes.filterGroup} gap={'xl'} h={48}>
          <LiitDropdown
            emptyText={t`Alla perioder`}
            menuItems={applicationPeriods}
            onChange={(periodId) => {
              const p = { ...parameters, applicationPeriodId: periodId, page: 1 };
              setParameters(p);
            }}
            selectedId={parameters.applicationPeriodId}
            showColorBox={true}
          />

          <Divider orientation="vertical" />
          <LiitMultiSelectDropdown
            itemsDescription={t`statusar`}
            emptyText={t`Alla statusar`}
            showColorBox={false}
            menuItems={[
              { id: `${ApplicationStatus.Submitted}`, text: t`Inlämnade` },
              { id: `${ApplicationStatus.Declined}`, text: t`Nekade` },
              { id: `${ApplicationStatus.Approved}`, text: t`Beviljade` },
              { id: `${ApplicationStatus.Deleted}`, text: t`Borttagna` },
            ]}
            selectedIds={parameters.statuses}
            onChange={(idArray) => {
              setParameters({ ...parameters, statuses: idArray, page: 1 });
            }}
          />

          {tags && tags.length > 0 && (
            <>
              <Divider orientation="vertical" />
              <LiitMultiSelectDropdown
                itemsDescription={t`kategorier`}
                emptyText={t`Alla kategorier`}
                showColorBox={true}
                menuItems={tags.map((x) => ({ id: x.id, text: x.name, color: x.color }))}
                selectedIds={parameters.tagIds as string[]}
                onChange={(idArray) => {
                  setParameters({ ...parameters, tagIds: idArray, page: 1 });
                }}
              />
            </>
          )}
          <Divider orientation="vertical" />
          <DebouncedTextInput
            delay={300}
            placeholder="Sök ..."
            defaultValue={parameters?.keyword}
            onChange={(text) => {
              const p = { ...parameters, keyword: text, page: 1 };
              setParameters(p);
            }}
          />
        </Group>

        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={applications}
            columnInfo={ApplicationsListColumns(tags && tags.length > 0 ? true : false)}
            emptyText={t`Vi kunde inte hitta några ansökningar.`}
            emptyIcon={IconMoodEmpty}
            onPageChange={(ts: TableState) => {
              const p = { ...parameters, page: ts.page };
              setParameters(p);
            }}
            onSortChange={(ts: TableState) => {
              const p = { ...parameters, orderBy: ts.orderBy, isAscending: ts.isAscending };
              setParameters(p);
            }}
            onRowClick={(id: string) => {
              navigate(`/applications/${id}`);
            }}
            fetchingData={isFetching}
            isPrintable={true}
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};

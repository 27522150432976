import { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Group,
  Input,
  Stack,
  Title,
  Text,
  Menu,
  Tabs,
  Box,
  Anchor,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../hooks/useSession';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { ApplicationPeriodEditForm } from './ApplicationPeriodEditForm';
import { useApi } from '../../hooks/useApi';
import { OrganisationUser } from '../../models/OrganisationUser';
import { Criteria } from '../../models/Criteria';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { IconChevronDown } from '@tabler/icons-react';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { isMissing } from 'utilitype';
import { Navigation } from '../../layout/Navigation';
import { Routes } from '../../layout/Routes';
import { LiitDrawer } from '../../components/LiitDrawer/LiitDrawer';
import { ManualApplication } from './ManualApplication';
import { t } from 'ttag';
import { ExportApplications } from './ExportApplications';
import { ImportApplications } from './import/ImportApplications';
import { ApplicantType } from '../../models/ApplicantType';
import Formatter from '../../utils/formatter';
import { BackButton } from '../../components/BackButton';
import { openConfirmModal } from '@mantine/modals';
import { PaymentOptionItems } from '../../models/PaymentOptions';
import {
  ApplicationPeriodType,
  ApplicationPeriodTypeItems,
} from '../../models/ApplicationPeriodType';
import { LiitTabs } from '../../components/LiitTabs/LiitTabs';
import { PeriodEmailTexts } from './PeriodEmailTexts';
import {
  CONTAINER_PADDING_WIDTH,
  CONTAINER_PADDING_TOP,
  MAX_FORM_WIDTH,
} from '../../layout/Layout';
import { getSettings } from '../../Settings';
import { ApplicationPeriodStatus } from '../../models/ApplicationPeriodStatus';

export const ApplicantTypes: Record<ApplicantType, string> = {
  [ApplicantType.Person]: 'Privatperson',
  [ApplicantType.Organization]: 'Organisation',
};

export const ApplicationPeriodView = () => {
  const api = useApi();
  const { applicationPeriodId } = useParams();
  const { selectedOrganisation } = useSession();
  const navigate = useNavigate();

  const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [users, setUsers] = useState<OrganisationUser[]>([]);
  const [editOpened, setEditOpened] = useState(false);
  const [inputApplicationOpened, setInputApplicationOpened] = useState(false);
  const [exportOpened, setExportOpened] = useState(false);
  const [importOpened, setImportOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation && applicationPeriodId) {
        setApplicationPeriod(
          await api.getApplicationPeriod(selectedOrganisation.id, applicationPeriodId),
        );
        setCriterias(await api.getCriterias(selectedOrganisation.id));
        setUsers(await api.getOrganisationUsers(selectedOrganisation.id));
      }
    };

    fetchData();
  }, [selectedOrganisation]);

  const openConfirmDeleteApplicationPeriodModal = () => {
    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort ansökningsperiod`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{t`Vill du verkligen ta bort perioden?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Ta bort', cancel: 'Avbryt' },
      onCancel: () => { },
      onConfirm: async () => {
        await api.deleteApplicationPeriod(selectedOrganisation!.id, applicationPeriodId!);
        navigate(Navigation[Routes.ApplicationPeriods].link);
      },
    });
  };

  const fetchUpdatedApplicationPeriod = async () => {
    if (isMissing(selectedOrganisation) || isMissing(applicationPeriod)) {
      return;
    }

    const updatedApplicationPeriod = await api.getApplicationPeriod(
      selectedOrganisation.id,
      applicationPeriod.id,
    );
    setApplicationPeriod(updatedApplicationPeriod);
  };

  const copyPeriod = async () => {
    if (isMissing(selectedOrganisation) || isMissing(applicationPeriod)) {
      return;
    }

    const response = await api.copyApplicationPeriod(
      selectedOrganisation.id,
      applicationPeriod?.id,
    );

    if (response.ok) {
      const r = Navigation[Routes.ApplicationPeriods].link + '/ongoing';
      navigate(r);
    }
  };

  const getUrl = () => `${getSettings().CLIENT_URL}/${selectedOrganisation?.slug}/applicationperiods/${applicationPeriod?.id}`;

  if (!selectedOrganisation || !applicationPeriod) {
    return <></>;
  }

  const canMultiImport = applicationPeriod.applicantType === ApplicantType.Person;

  return (
    <GrantMeContainer size="xl">
      <Stack gap={0}>
        <GrantMeTitleContainer>
          <Group wrap={'nowrap'} style={{ overflow: 'hidden' }}>
            <BackButton route={Routes.ApplicationPeriods} />
            <Title
              order={3}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {applicationPeriod.title}
            </Title>
          </Group>
          <Menu
            transitionProps={{ transition: 'pop-top-right' }}
            position="top-end"
            width={220}
            withinPortal
            onClose={() => setMenuOpened(false)}
            opened={menuOpened}
            closeOnClickOutside={true}>
            <Menu.Target>
              <Button
                onClick={() => setMenuOpened(!menuOpened)}
                rightSection={<IconChevronDown size={18} stroke={1.5} />}>
                Alternativ
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                disabled={applicationPeriod.isArchived}
                onClick={() => setInputApplicationOpened(true)}>
                Skapa manuell ansökan
              </Menu.Item>
              <Menu.Item
                disabled={applicationPeriod.isArchived || applicationPeriod.hasApplications}
                onClick={() => openConfirmDeleteApplicationPeriodModal()}>
                Ta bort period
              </Menu.Item>
              <Menu.Item onClick={() => setExportOpened(true)}>Exportera ansökningar</Menu.Item>
              {canMultiImport && (
                <Menu.Item
                  disabled={applicationPeriod.isArchived}
                  onClick={() => setImportOpened(true)}>
                  Importera ansökningar
                </Menu.Item>
              )}
              <Menu.Item onClick={() => copyPeriod()}>{t`Kopiera period`}</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </GrantMeTitleContainer>
        <LiitTabs defaultValue={'information'}>
          <Tabs.List>
            <Tabs.Tab value="information">Information</Tabs.Tab>
            <Tabs.Tab value="emailTemplate">E-posttexter</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={'information'}>
            <Box
              pt={CONTAINER_PADDING_TOP}
              pl={CONTAINER_PADDING_WIDTH}
              maw={MAX_FORM_WIDTH}
              pb={16}>
              <Grid gutter={12}>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Titel">
                    <Text size={'sm'}>{applicationPeriod.title}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Beskrivning">
                    <Text size={'sm'}>{applicationPeriod.description}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Formulär">
                    <Text size={'sm'}><Group>{applicationPeriod.formName}<Anchor href={`/settings/forms/${applicationPeriod.formId}`} >Redigera</Anchor></Group></Text>
                  </Input.Wrapper>
                </Grid.Col>
                {applicationPeriod.isPublished && applicationPeriod.status === ApplicationPeriodStatus.Ongoing &&
                  <Grid.Col span={12}>
                    <Input.Wrapper label="Direktlänk till ansökningsperioden">
                      <Anchor href={getUrl()} target="_blank" size="sm">
                        {getUrl()}
                      </Anchor>
                    </Input.Wrapper>
                  </Grid.Col>
                }
                <Grid.Col span={4}>
                  <Input.Wrapper label="Målgrupp">
                    <Text size={'sm'}>{ApplicantTypes[applicationPeriod.applicantType]}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Utbetalning">
                    <Text size={'sm'}>{PaymentOptionItems[applicationPeriod.paymentOption]}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Budget">
                    <Text size={'sm'}>{Formatter.formatCurrency(applicationPeriod.budget)}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Typ">
                    <Text size={'sm'}>{ApplicationPeriodTypeItems[applicationPeriod.type]}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Startdatum">
                    <Text size={'sm'}>
                      {Formatter.formatDateString(applicationPeriod.startDate)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  {applicationPeriod.type != ApplicationPeriodType.Ongoing && (
                    <Input.Wrapper label="Slutdatum">
                      <Text size={'sm'}>
                        {Formatter.formatDateString(applicationPeriod.endDate)}
                      </Text>
                    </Input.Wrapper>
                  )}
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Publik">
                    <Text size={'sm'}>{applicationPeriod.isPublished ? 'Ja' : 'Nej'}</Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Granskningsbar">
                    <Text size={'sm'}>{applicationPeriod.isReviewable ? 'Ja' : 'Nej'}</Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Input.Wrapper label="Bedömningskriterier">
                    <Text size={'sm'}>
                      {applicationPeriod.criteriaIds.length > 0
                        ? applicationPeriod.criteriaIds
                          .map((x) => criterias.find((y) => y.id === x)?.title)
                          .join(', ')
                        : t`Inga`}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input.Wrapper label={t`Maxpoäng på bedömningskriterier`}>
                    <Text size={'sm'}>{applicationPeriod.maxCriteriaScore}</Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label="Granskare">
                    <Text size={'sm'}>
                      {applicationPeriod.reviewerIds.length > 0
                        ? applicationPeriod.reviewerIds
                          .map((x) => users.find((y) => y.id === x)?.firstname)
                          .join(', ')
                        : t`Alla`}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
              {!applicationPeriod.isArchived && (
                <Group justify={'start'} pt={24} pr={CONTAINER_PADDING_WIDTH}>
                  <Button onClick={() => setEditOpened(true)}>Redigera</Button>
                </Group>
              )}
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value={'emailTemplate'}>
            <PeriodEmailTexts
              onSave={() => fetchUpdatedApplicationPeriod()}
              selectedOrganisation={selectedOrganisation}
              applicationPeriod={applicationPeriod}
            />
          </Tabs.Panel>
        </LiitTabs>
      </Stack>

      <LiitDrawer opened={editOpened} onClose={() => setEditOpened(false)} size="50%">
        <ApplicationPeriodEditForm
          applicationPeriod={applicationPeriod}
          selectedOrganisation={selectedOrganisation}
          onClose={() => {
            setEditOpened(false);
          }}
          onSave={() => {
            fetchUpdatedApplicationPeriod();
            setEditOpened(false);
          }}
        />
      </LiitDrawer>

      <LiitDrawer
        withCloseButton
        opened={inputApplicationOpened}
        onClose={() => setInputApplicationOpened(false)}
        size={'75%'}>
        <ManualApplication
          applicationPeriod={applicationPeriod}
          onComplete={() => setInputApplicationOpened(false)}
        />
      </LiitDrawer>

      <LiitDrawer opened={exportOpened} onClose={() => setExportOpened(false)} size={'50%'}>
        <ExportApplications
          applicationPeriod={applicationPeriod}
          onComplete={() => setExportOpened(false)}
        />
      </LiitDrawer>

      {canMultiImport && (
        <LiitDrawer opened={importOpened} onClose={() => setImportOpened(false)} size={'80%'}>
          <ImportApplications
            applicationPeriod={applicationPeriod}
            onComplete={() => setImportOpened(false)}
          />
        </LiitDrawer>
      )}
    </GrantMeContainer>
  );
};

/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppShell, useMantineTheme } from '@mantine/core';
import { ContentContainer } from './ContentContainer';
import { MenuHeader } from './MenuHeader';
import { GrantMeNavbar } from './GrantMeNavbar';
import { useDisclosure } from '@mantine/hooks';

export const MAX_FORM_WIDTH = 900;
export const CONTAINER_PADDING_WIDTH = 40;
export const CONTAINER_PADDING_TOP = 20;

interface LayoutProps {
  loadNavBar: boolean;
}

export const BURGER_TRIGGER = 'xl';

export const Layout: React.FC<LayoutProps> = ({ loadNavBar }) => {
  const [opened, { toggle }] = useDisclosure();

  const location = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(loadNavBar);

  useEffect(() => {
    if (location.pathname.startsWith('/settings/forms/')) {
      setShowMenu(false);
    } else {
      setShowMenu(loadNavBar);
    }

  }, [location]);

  return (
    <AppShell
      header={{ height: 64 }}
      navbar={{ width: '240px', breakpoint: BURGER_TRIGGER, collapsed: { mobile: !opened, desktop: !showMenu } }}
      styles={{
        root: {
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        main: {
          display: 'flex',
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
        },
        navbar: {
          width: '240px',
        },
      }}
    >
      <AppShell.Header>
        <MenuHeader simpleMode={!showMenu} menuOpened={opened} toggleMenu={toggle} />
      </AppShell.Header>
      {showMenu &&
        <AppShell.Navbar>
          <GrantMeNavbar />
        </AppShell.Navbar>
      }
      <AppShell.Main>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </AppShell.Main>
    </AppShell>
  );
};

import {
  IconAlignJustified,
  IconCalendar,
  IconCash,
  IconCheckbox,
  IconCircle,
  IconFile,
  IconFlag,
  IconForms,
  IconH4,
  IconLock,
  IconMoneybag,
  IconRectangle,
  IconRepeat,
  IconSelect,
  IconTable,
  IconUser,
} from '@tabler/icons-react';
import { t } from 'ttag';
import { FormElementType } from '../models/FormElementType';
import { ToolboxItem } from './TooboxItem';

const {
  TextArea,
  Dropdown,
  FileGroup,
  CheckboxGroup,
  RadioButtonGroup,
  AmountsTable,
  Repeater,
  TypedTextBox,
  SectionTitle,
  Paragraph,
  ContactPerson,
  DatePickerElement,
  ContactPersonOrganization,
  IdentityNumberPerson,
  IdentityNumberOrganization,
  WayOfPayment,
} = FormElementType;

export const ToolboxItems: Record<FormElementType, ToolboxItem> = {
  [FormElementType.TextArea]: {
    label: t`Textarea`,
    type: TextArea,
    icon: <IconRectangle size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.Dropdown]: {
    label: t`Dropdown`,
    type: Dropdown,
    icon: <IconSelect size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.FileGroup]: {
    label: t`Filuppladdning`,
    type: FileGroup,
    icon: <IconFile size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.RadioButtonGroup]: {
    label: t`Radioknappar`,
    type: RadioButtonGroup,
    icon: <IconCircle size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.CheckboxGroup]: {
    label: t`Checkbox`,
    type: CheckboxGroup,
    icon: <IconCheckbox size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.AmountsTable]: {
    label: t`Beloppstabell`,
    type: AmountsTable,
    icon: <IconTable size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.Repeater]: {
    label: t`Repeterare`,
    type: Repeater,
    icon: <IconRepeat size={14} />,
    mandatory: false,
    canResize: false,
  },
  [FormElementType.TypedTextBox]: {
    label: t`Textbox`,
    type: TypedTextBox,
    icon: <IconForms size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.SectionTitle]: {
    label: t`Rubrik`,
    type: SectionTitle,
    icon: <IconH4 size={14} />,
    mandatory: false,
    canResize: false,
  },
  [FormElementType.Paragraph]: {
    label: t`Brödtext`,
    type: Paragraph,
    icon: <IconAlignJustified size={14} />,
    mandatory: false,
    canResize: true,
  },  
  [FormElementType.DatePickerElement]: {
    label: t`Datumväljare`,
    type: DatePickerElement,
    icon: <IconCalendar size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.ContactPerson]: {
    label: t`Kontaktperson`,
    type: ContactPerson,
    icon: <IconUser size={14} />,
    limit: 1,
    mandatory: false,
    canResize: false,
  },
  [FormElementType.ContactPersonOrganization]: {
    label: t`Kontaktperson`,
    type: ContactPersonOrganization,
    icon: <IconUser size={14} />,
    limit: 1,
    mandatory: true,
    canResize: false,
  },
  [FormElementType.IdentityNumberPerson]: {
    label: t`Personnummer`,
    type: IdentityNumberPerson,
    icon: <IconLock size={14} />,
    limit: 1,
    mandatory: true,
    canResize: true,
  },
  [FormElementType.IdentityNumberOrganization]: {
    label: t`Org-Nummer`,
    type: IdentityNumberOrganization,
    icon: <IconLock size={14} />,
    limit: 1,
    mandatory: true,
    canResize: true,
  },
  [FormElementType.WayOfPayment]: {
    label: t`Utbetalningssätt`,
    type: WayOfPayment,
    icon: <IconMoneybag size={14} />,
    limit: 1,
    mandatory: true,
    canResize: false,
  },
};

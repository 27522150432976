import { Group, Text } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { formatValue } from '../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { RenderCell, CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { bytes, megaBytes } from 'utilitype';

const getSize = (size): string => {
  if (size) {
    const kiloByte = 1000;
    const megabyte = kiloByte * 1000;

    if (size >= megabyte) {
      return `${formatValue(megaBytes({ kB: size / 1000 }), PropertyType.Number)} MB`;
    }

    if (size >= kiloByte) {
      return `${formatValue(size / 1000, PropertyType.Number)} KB`;
    }


    return `${formatValue(size, PropertyType.Number)} bytes`;
  }

  return '-';
};

export const RenderDocumentFileSizeCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { cellValue, rowValues } = rowInformation;

  return (
    <Text size='sm'>{formatValue(getSize(cellValue), PropertyType.Text)}</Text>
  );
};

export enum Routes {
  Applications = 1,
  Approvals = 2,
  Payments = 3,
  Archive = 4,
  ApplicationPeriods = 5,
  Settings = 6,
  ApplicationReports = 7,
  Reviews = 8,
  Documents = 9,
}

import { ReactNode } from 'react';
import { formatValue } from '../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { RenderCell, CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';

export const RenderDocumentUserCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { cellValue, rowValues } = rowInformation;

  return (
    <Group>
      <IconUser />
      <Text size='sm'>{formatValue(cellValue, PropertyType.Text)}</Text>
    </Group>
  );
};
